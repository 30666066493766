import {
    ADD_DATA_SET, CLEAR_DATA_SETS, DELETE_DATA_SETS, SET_DATA_SETS,
} from "../constants/action-types";

const initialState = [];

const dataSets = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DATA_SET:
            return [...state, action.payload];

        case CLEAR_DATA_SETS:
            return initialState;

        case DELETE_DATA_SETS:
            return state.filter((dataSet) => {
                return !action.payload.some((deletedDataSet) => {
                    return deletedDataSet.id.toString() === dataSet.id.toString();
                });
            });

        case SET_DATA_SETS:
            return action.payload;

        default:
            return state;
    };
};

export default dataSets;
