/**
 * Convert milliseconds since epoch into a human readable string
 * @param {Number} dateMilliseconds milliseconds since epoch
 * @return {String} a human readable date
 */
export default (dateMilliseconds) => {
    const date = new Date(parseInt(dateMilliseconds));
    const dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return dateString;
};
