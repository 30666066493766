import React, {
    forwardRef, useState, useEffect,
} from "react";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    Button, Slide, Dialog, DialogContent,
} from "@material-ui/core";
import {
    makeStyles,
} from "@material-ui/core/styles";
import {
    setBuildNewProjectDialogClosed,
} from "../../actions/dialog";
import {
    NavigateBefore as NavigateBeforeIcon,
} from "@material-ui/icons";
import DataSetTable from "../DataSets/NewDataSetDialog";
import dateString from "../../sharedFunctions/dateString";
import {
    buildProject,
} from "../../actions/projects";

const ONE_TABLE_NUM_ROWS = 10;
const TWO_TABLES_NUM_ROWS = 5;

/**
 * Slide transition for this dialog
 */
const SlideLeftTransition = forwardRef((props, ref) => {
    return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    dialogTitle: {
        textAlign: "center",
        padding: "16px 24px",
        fontWeight: "bold",
        fontSize: "22px",
    },
    buttonContainer: {
        padding: "8px 24px",
    },
    backButton: {
        float: "left",
    },
    nextButton: {
        float: "right",
    },
});

/**
 * The BuildNewProjectDialog Component
 * @param {Object} props data from the parent component
 * @return {JSX} the BuildNewProjectDialog Component
 */
export default (props) => {
    const {
        selectedDataSets, projectName, userFullName,
    } = props;

    const classes = useStyles();

    const dispatch = useDispatch();
    const dialogOpen = useSelector((state) => {
        return state.dialogs.buildNewProjectDialogOpen;
    });

    const [parsedDataSets, setParsedDataSets] = useState([]);

    const [firstDataTableName, setFirstDataTableName] = useState("");
    const [secondDataTableName, setSecondDataTableName] = useState("");

    const [firstDataTableDateCreated, setFirstDataTableDateCreated] = useState("");
    const [secondDataTableDateCreated, setSecondDataTableDateCreated] = useState("");

    const [firstCsvMetadata, setFirstCsvMetadata] = useState([]);
    const [secondCsvMetadata, setSecondCsvMetadata] = useState([]);

    // every time the selected data sets change, format them so they can be displayed properly
    // in the tables in the NewDataSetDialog
    useEffect(() => {
        setParsedDataSets(selectedDataSets.map((dataSet) => {
            const modifiedDataSet = {
                ...dataSet,
                csvMetadata: JSON.parse(dataSet.csvMetadata),
            };

            modifiedDataSet.csvMetadata.colMeta = modifiedDataSet.csvMetadata.colMeta.map((metadata) => {
                return {
                    ...metadata,
                    checked: true,
                };
            });

            return modifiedDataSet;
        }));
    }, [selectedDataSets]);

    // every time the selected data sets change, re-initialize the state variables that track changes
    // to the data sets in the NewDataSetDialog
    useEffect(() => {
        parsedDataSets.forEach((dataSet, index) => {
            if (index === 0) {
                setFirstDataTableName(dataSet.dataTableName);
                setFirstDataTableDateCreated(dataSet.dateCreated);
                setFirstCsvMetadata(dataSet.csvMetadata.colMeta);
            } else {
                setSecondDataTableName(dataSet.dataTableName);
                setSecondDataTableDateCreated(dataSet.dateCreated);
                setSecondCsvMetadata(dataSet.csvMetadata.colMeta);
            }
        });
    }, [parsedDataSets]);

    /**
     * Determine how many rows of data should be shown in the NewDataSetDialog
     * @return {Number} the number of rows that should be shown in the NewDataSetDialog
     */
    const getNumRows = () => {
        return parsedDataSets.length === 1 ? ONE_TABLE_NUM_ROWS : TWO_TABLES_NUM_ROWS;
    };

    /**
     * Build an object to be sent to the flask server to build a new project
     */
    const buildNewProject = () => {
        const projectJson = {
            projVersion: 0.1,
            // projID: guidGenerator(),
            projName: projectName,
            projCreator: userFullName,
            projDtColumns: {
                dt1Columns: [],
                dt2Columns: [],
            },
            datatables: [],
        };

        const projectMetadata = {
            projName: projectName,
            dateCreated: new Date().getTime(),
            dt1: {
                name: firstDataTableName,
                dateCreated: firstDataTableDateCreated,
                columnsMetadata: [],
            },
        };

        // remove the columns that were unchecked
        let checkedColumns = [...firstCsvMetadata].filter((metadata) => {
            return metadata.checked;
        }).map((metadata) => {
            // delete the "checked" metadata
            delete metadata.checked;
            projectJson.projDtColumns.dt1Columns.push(metadata.colNumber);
            projectMetadata.dt1.columnsMetadata.push({
                displayName: metadata.colDisplayName,
                type: metadata.colType,
            });
            return metadata;
        });

        let dataSet = parsedDataSets[0];
        let dtMeta = dataSet.csvMetadata.dtMeta;

        projectMetadata.dt1.recordCount = dtMeta.dtRecordCount;

        projectJson.datatables.push({
            dtVersion: dtMeta.dtVersion,
            dtName: firstDataTableName,
            dtCreationTime: dateString(dataSet.dateCreated),
            dtCreator: userFullName,
            dtURL: dtMeta.dtURL,
            dtCsvTimeColPos: dtMeta.dtCsvTimeColPos,
            dtRecordCount: dtMeta.dtRecordCount,
            colMeta: checkedColumns,
        });

        if (secondCsvMetadata.length) {
            projectMetadata.dt2 = {
                name: secondDataTableName,
                dateCreated: secondDataTableDateCreated,
                columnsMetadata: [],
            };

            // remove the columns that were unchecked
            checkedColumns = [...secondCsvMetadata].filter((metadata) => {
                return metadata.checked;
            }).map((metadata) => {
                // delete the "checked" metadata
                delete metadata.checked;
                // track the order of the columns
                projectJson.projDtColumns.dt2Columns.push(metadata.colNumber);
                projectMetadata.dt2.columnsMetadata.push({
                    displayName: metadata.colDisplayName,
                    type: metadata.colType,
                });
                return metadata;
            });

            dataSet = parsedDataSets[1];
            dtMeta = dataSet.csvMetadata.dtMeta;

            projectMetadata.dt2.recordCount = dtMeta.dtRecordCount;

            projectJson.datatables.push({
                dtVersion: dtMeta.dtVersion,
                dtName: secondDataTableName,
                dtCreationTime: dateString(dataSet.dateCreated),
                dtCreator: userFullName,
                dtURL: dtMeta.dtURL,
                dtCsvTimeColPos: dtMeta.dtCsvTimeColPos,
                dtRecordCount: dtMeta.dtRecordCount,
                colMeta: checkedColumns,
            });
        }

        const params = {
            projectJson: projectJson,
            projectMetadata: projectMetadata,
        };

        dispatch(buildProject(params));
    };

    return (
        <Dialog
            TransitionComponent={SlideLeftTransition}
            maxWidth={"xl"}
            fullWidth={true}
            open={dialogOpen}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <div className={classes.dialogTitle}>
                New Project - {projectName}
            </div>
            <DialogContent>
                {parsedDataSets.map((dataSet, dataSetIndex) => {
                    return (
                        <div
                            key={dataSetIndex}
                            style={{
                                // if there are two data sets, add extra margin
                                marginBottom: (dataSetIndex === 0) && (parsedDataSets.length === 2) ?
                                    "25px" : "",
                            }}
                        >
                            <DataSetTable
                                fileId={dataSet.fileId}
                                fileName={dataSet.dataTableName}
                                csvMetadata={dataSet.csvMetadata}
                                showInDialog={false}
                                numRows={getNumRows()}
                                updateDataTableDisplayName={dataSetIndex === 0 ?
                                    setFirstDataTableName : setSecondDataTableName}
                                updateCsvMetadata={dataSetIndex === 0 ? setFirstCsvMetadata : setSecondCsvMetadata}
                            />
                        </div>
                    );
                })}
            </DialogContent>
            <div className={classes.buttonContainer}>
                <Button variant="outlined" onClick={() => {
                    dispatch(setBuildNewProjectDialogClosed());
                }} className={classes.backButton}>
                    <NavigateBeforeIcon /> Back
                </Button>
                <Button variant="outlined" className={classes.nextButton} onClick={buildNewProject}>
                    Build
                </Button>
            </div>
        </Dialog>
    );
};
