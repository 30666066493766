/* eslint-disable require-jsdoc */
import {
    call, fork, put, takeEvery,
} from "redux-saga/effects";
import {
    BUILD_PROJECT_REQUEST, DELETE_PROJECT_REQUEST,
} from "../constants/action-types";
import * as api from "../api/projects";
import {
    setBuildNewProjectDialogClosed,
    setNewProjectDataSetsDialogClosed,
    setNewProjectMetadataDialogClosed,
} from "../actions/dialog";
import {
    addBuiltProject, deleteProject,
} from "../actions/projects";
import {
    setErrorMessage,
} from "../actions/alert";
import {
    showModal, hideModal,
} from "../actions/modal.js";
import {
    loadModel, clearModel,
} from "../actions/index";
import {
    API_URL,
} from "../../config.js";

function* buildProjectRequest(action) {
    yield put(showModal("Building your project. This may take several minutes, please stand by..."));
    try {
        const result = yield call(api.buildProject, action.payload);
        yield put(addBuiltProject(JSON.parse(result.newProject)));
        yield put(setBuildNewProjectDialogClosed());
        yield put(setNewProjectMetadataDialogClosed());
        yield put(setNewProjectDataSetsDialogClosed());

        // clear the current model (if applicable)
        yield put(clearModel());
        const projId = JSON.parse(result.newProject).projId;
        // the load the new model
        yield put(loadModel({
            model_url: `${API_URL}/api/model/${projId}`,
        }));
    } catch (error) {
        yield put(setErrorMessage("We're currently experiencing issues on our end and were unable to build the " +
            "project. Please refresh the application. If the problem persists, reach out to us directly via email."));
    }
    yield put(hideModal());
};

function* watchBuildProjectRequest() {
    yield takeEvery(BUILD_PROJECT_REQUEST, buildProjectRequest);
};

function* deleteProjectRequest(action) {
    yield put(showModal("Deleting your project, please standy by..."));
    try {
        const result = yield call(api.deleteProject, {
            projId: action.payload.projId,
        });
        yield put(deleteProject({
            projId: result.projId,
        }));
        // if the project that was just deleted is open on the canvas, clear it
        if (action.payload.clearCanvas) {
            yield put(clearModel());
        }
    } catch (error) {
        yield put(setErrorMessage("We're currently experiencing issues on our end and were unable to delete the " +
            "project. Please refresh the application. If the problem persists, reach out to us directly via email."));
    }
    yield put(hideModal());
};

function* watchDeleteProjectRequest() {
    yield takeEvery(DELETE_PROJECT_REQUEST, deleteProjectRequest);
};

const projectsSagas = [
    fork(watchBuildProjectRequest),
    fork(watchDeleteProjectRequest),
];

export default projectsSagas;
