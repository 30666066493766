import {
    API_URL,
} from "../../config.js";

export const getUser = () => {
    return fetch(`${API_URL}/api/getUser`, {
        credentials: "include",
    }).then((response) => {
        return response.json();
    });
};

export const loginUser = (params) => {
    const url = new URL(`${API_URL}/api/login`);
    Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key]);
    });

    return fetch(url, {
        credentials: "include",
    }).then((response) => {
        return response.json();
    });
};

export const logoutUser = () => {
    return fetch(`${API_URL}/api/logout`, {
        credentials: "include",
    }).then((response) => {
        return response.json();
    });
};
