import React, {
    forwardRef,
} from "react";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    Button, Dialog, IconButton, Zoom,
} from "@material-ui/core";
import {
    StyledDialogTitle, StyledDialogContent, StyledDialogActions,
} from "../../styles/dialog";
import {
    makeStyles,
} from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
    setConfirmDeleteDataSetsDialogClosed,
} from "../../actions/dialog";
import {
    deleteDataSetsRequest,
} from "../../actions/dataSets";

/**
 * Zoom transition for this dialog
 */
const ZoomTransition = forwardRef((props, ref) => {
    return <Zoom direction="left" ref={ref} {...props} />;
});

/**
 * Styles to be used as classes in this component
 */
const useStyles = makeStyles({
    iconButton: {
        position: "absolute",
        left: "0%",
        top: "50%",
        transform: "translateY(-50%)",
    },
    positionRelative: {
        position: "relative",
    },
    minWidth: {
        " & .MuiDialog-paperWidthSm": {
            minWidth: "250px",
        },
    },
    textAlignLeft: {
        textAlign: "left",
    },
});

/**
 * The ConfirmDeleteDataSetsDialog Component
 * @param {Object} props data from the parent component
 * @return {JSX} the ConfirmDeleteDataSetsDialog Component
 */
export default (props) => {
    const {
        selectedDataSets,
    } = props;

    const classes = useStyles();

    const dispatch = useDispatch();

    const dialogOpen = useSelector((state) => {
        return state.dialogs.confirmDeleteDataSetsDialogOpen;
    });

    /**
     * Submit a request to the server to delte the selected data sets
     */
    const submit = () => {
        const dataSetsToDelete = selectedDataSets.map((dataSet) => {
            return {
                id: dataSet.id,
                fileUrl: JSON.parse(dataSet.csvMetadata).dtMeta.dtURL,
            };
        });

        dispatch(deleteDataSetsRequest(dataSetsToDelete));
    };

    /**
     * Close this dialog
     */
    const closeConfirmDeleteDataSetsDialog = () => {
        dispatch(setConfirmDeleteDataSetsDialogClosed());
    };

    /**
     * Render the data set(s) that will be deleted
     * @return {JSX} a list of data sets the user wants to delete
     */
    const getDataSetsToDelete = () => {
        return selectedDataSets.map((dataSet) => {
            return (
                <p key={dataSet.id}>{dataSet.dataTableName} - {dataSet.originalFileName}</p>
            );
        });
    };

    return (
        <Dialog
            TransitionComponent={ZoomTransition}
            className={classes.minWidth}
            open={dialogOpen}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <StyledDialogTitle className={classes.positionRelative}>
                <IconButton onClick={closeConfirmDeleteDataSetsDialog} className={classes.iconButton}>
                    <ArrowBackIcon />
                </IconButton>
                <span>Confirm Deletion</span>
            </StyledDialogTitle>
            <StyledDialogContent className={classes.textAlignLeft}>
                {getDataSetsToDelete()}
            </StyledDialogContent>
            <StyledDialogActions>
                <Button onClick={submit} variant="outlined">
                    Submit
                </Button>
            </StyledDialogActions>
        </Dialog>
    );
};
