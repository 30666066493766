import {
    GET_USER_REQUEST, LOGIN_USER, LOGIN_USER_REQUEST, LOGOUT_USER_REQUEST, LOGOUT_USER,
} from "../constants/action-types";

// check if a user is logged in when the app is first loaded
export const getUserRequest = () => {
    return {
        type: GET_USER_REQUEST,
    };
};

// a user is attempting to login
export const loginUserRequest = (payload) => {
    return {
        type: LOGIN_USER_REQUEST,
        payload,
    };
};

// a user has just logged in - save the user info in the redux store
export const loginUser = (payload) => {
    return {
        type: LOGIN_USER,
        ...payload,
    };
};

// a user is attempting to logout
export const logoutUserRequest = () => {
    return {
        type: LOGOUT_USER_REQUEST,
    };
};

// a user has just logged out - delete the user info in the redux store
export const logoutUser = () => {
    return {
        type: LOGOUT_USER,
    };
};
