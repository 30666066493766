import React from "react";
import {
    StyledTextField,
} from "../styles/dialog";
/**
 * Returns a styled text field with custom attributes
 * @param {String} label the label to show in/over the test field
 * @param {Function} onChange the funciton to call when text in the text field has changed
 * @param {String} type the type of the text field
 * @param {String} value the variable to bind to the text field
 * @param {Boolean} error boolean that indicates if the helperText should be shown or hidden
 * @param {String} helperText an error message
 * @return {JSX} a styled text field with custom attributes
 */
const input = (label, onChange, type, value, error, helperText) => {
    return (
        <StyledTextField
            error={error}
            helperText={helperText}
            label={label}
            margin="dense"
            onChange={onChange}
            type={type}
            value={value}
            variant="outlined"
        />
    );
};

export default input;
