import * as Papa from "papaparse";
import {
    setUnion, setDifference, setIntersection,
} from "../../utils";

const initialState = {
    projectId: "",
    modelLoaded: false,
    metadata: {},
    basemap: false,
    nodes: [],
    curve2nodesMap: null,
    curves1: [],
    curves2: [],
    axes: [],
    // should be an array holding a series of sets over time
    selectedNodes: new Set(),
    activeCurves: [],
    nodeRefCounts: [],
};

const canvas = (state = initialState, action) => {
    switch (action.type) {
        case "METADATA_LOADED":
            const metadata = action.metadataPayload.data;
            metadata.model_width = parseInt(metadata.model_width);
            metadata.model_height = parseInt(metadata.model_height);

            return {
                ...state,
                metadata: metadata,
            };

        case "CONFIG_LOADED":
            return {
                ...state,
                config: action.configPayload.data,
            };

        case "BASEMAP_LOADED":
            return {
                ...state,
                basemap: true,
            };

        case "NODES_LOADED":
            // parse nodes csv into an array of node objects;
            // simultaneously convert node coords & curve indexes to arrays
            const parsedNodes = Papa.parse(action.nodesPayload.data, {
                header: true,
                dynamicTyping: true,
                transform: (value) => {
                    if (value.startsWith("[") || value.startsWith("{")) {
                        try {
                            // remove brackets from input coordinates string and then convert to an array
                            const arrayStr = value.slice(1, value.length - 1);
                            return JSON.parse(`[${arrayStr}]`);
                        } catch (err) {
                            return value;
                        }
                    } else {
                        return value;
                    }
                },
            });

            // TODO: resolve why this is happening
            // remove bad last row inserted for some reason during parsing
            // possible solution: skipEmptyLines: true,
            parsedNodes.data.pop();

            // now build curve id to node id index
            const nodes = parsedNodes.data;
            const curve2nodesMap = new Map();
            nodes.forEach((node, nodeId) => {
                // TODO: why is this not always an array??
                // get its curve ids & put into an array
                let curves;
                if (Array.isArray(node.curves)) {
                    curves = node.curves;
                } else {
                    curves = [node.curves];
                }

                curves.forEach((curve) => {
                    if (curve2nodesMap.has(curve)) {
                        // curve id key is already in the map, add nodeId to its node id array
                        curve2nodesMap.get(curve).push(nodeId);
                    } else {
                        // create a new array containing first element & add to map
                        curve2nodesMap.set(curve, [nodeId]);
                    }
                });
            });

            return {
                ...state,
                nodes: state.nodes.concat(parsedNodes.data),
                curve2nodesMap: curve2nodesMap,
            };

        case "CURVES1_LOADED":
            return {
                ...state,
                curves1: state.curves1.concat(action.curves1Payload.data),
            };

        case "CURVES2_LOADED":
            return {
                ...state,
                curves2: state.curves2.concat(action.curves2Payload.data),
            };

        case "AXES_LOADED":
            return {
                ...state,
                axes: state.axes.concat(action.axesPayload.data),
            };

        case "MODEL_LOADED":
            const newNodes = state.nodes;
            const labelMaxCharLen = state.config.cat_label_max_char_len;

            // truncate overly long category value strings
            // TODO: the original strings should be maintained somewhere
            newNodes.forEach((node) => {
                if (node.dtype === "category") {
                    if (node.val.length > labelMaxCharLen) {
                        node.val = `${node.val.slice(0, labelMaxCharLen)}...`;
                    }
                }
            });

            const allNodes = state.nodes.concat(newNodes);

            return {
                ...state,
                nodes: allNodes,
                nodeRefCounts: new Array(allNodes.length).fill(0),
                modelLoaded: true,
            };

        case "NODES_SELECTED":
            // pull the ids of the newly selected nodes out of the message payload
            // convert payload array to set
            let selectedNodes = new Set(action.payload.nodeIds);

            // get the existing node selections from state
            // set
            const prevSelectedNodes = state.selectedNodes;

            // get the set of currently active curves from state array
            // array
            let activeCurves = state.activeCurves;

            // update node selections and curve activations
            if (selectedNodes.size === 1) {
                // we're dealing with a single node id; extract it from the set
                const iter = selectedNodes.values();
                const nodeId = iter.next().value;

                // grab its curve id array and put into a set
                let nodeCurves;
                const curves = state.nodes[nodeId].curves;
                if (Array.isArray(curves)) {
                    // it's an array
                    nodeCurves = new Set(state.nodes[nodeId].curves);
                } else {
                    // it's just a single value
                    nodeCurves = new Set([state.nodes[nodeId].curves]);
                }

                // if some or all of the node's curves are already active, remove them all
                let ac = new Set(activeCurves); // ! convert activeCurves to a set
                const intersection = setIntersection(ac, nodeCurves);
                if (intersection.size > 0) {
                    // remove the node's curves
                    ac = setDifference(ac, nodeCurves);

                    // turn the node "off" by removing it from the selection set if it's already in there
                    selectedNodes = setDifference(prevSelectedNodes, selectedNodes);
                } else {
                    // none of the node's curves are already in activeCurves, so add them all
                    ac = setUnion(ac, nodeCurves);

                    // turn the node "on" by adding it to the existing selection set
                    selectedNodes = setUnion(selectedNodes, prevSelectedNodes);
                }

                activeCurves = [...ac]; // ! convert set back to array for storage
            } else {
                // multiple nodes selected; merge with previous selections and activate all of their associated curves
                selectedNodes = setUnion(selectedNodes, prevSelectedNodes);
                selectedNodes.forEach((nodeId) => {
                    activeCurves = activeCurves.concat(state.nodes[nodeId].curves); // ! TODO: change to set op
                });
            }

            // accumulate node curve reference counts
            // array holding # of active curves passing through each node
            const nodeRefCounts = new Array(state.nodes.length).fill(0);
            const curve2nodesIdx = state.curve2nodesMap;
            activeCurves.forEach((curve) => {
                const nodeIds = curve2nodesIdx.get(curve);
                nodeIds.forEach((nodeId) => {
                    nodeRefCounts[nodeId] += 1;
                });
            });

            // clear now stranded (i.e. ref count == 0) nodes from selectedNodes
            state.nodes.forEach((node, nodeId) => {
                if ((selectedNodes.has(nodeId)) && (nodeRefCounts[nodeId] === 0)) {
                    selectedNodes.delete(nodeId);
                }
            });

            // push the newly selected node ids, active curve ids, and node ref counts into state
            return {
                ...state,
                // activeCurves: state.activeCurves.concat(activeCurves) // ! <--- this is correct, modify the below
                selectedNodes: selectedNodes, // ! set
                activeCurves: activeCurves, // ! array
                nodeRefCounts: nodeRefCounts, // ! array
            };

        case "CLEAR_SELECTIONS":
            return {
                ...state,
                // activeCurves: state.activeCurves.concat(activeCurves) // ! <--- this is correct, modify the below
                selectedNodes: new Set(), // ! set
                activeCurves: [], // ! array
                nodeRefCounts: [],
            };

        case "SET_PROJECT_ID":
            return {
                ...state,
                projectId: action.projectId,
            };

        case "CLEAR_MODEL":
            return {
                ...state,
                projectId: "",
                modelLoaded: false,
                metadata: {},
                config: {},
                basemap: false,
                nodes: [],
                curve2nodesMap: null,
                curves1: [],
                curves2: [],
                axes: [],
                selectedNodes: new Set(),
                activeCurves: [],
                nodeRefCounts: [],
            };

        default:
            return state;
    }
};

export default canvas;
