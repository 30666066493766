import React/* , { useEffect, useState, useRef, }*/ from "react";
// import FOG from "vanta/dist/vanta.fog.min.js";
import Alert from "./Alert";
import Canvas from "./canvas/Canvas";
import CreateAccount from "./CreateAccount";
import LoadProjectDialog from "./LoadProjectDialog";
import Login from "./Login";
import Modal from "./Modal";
import NavigationBar from "./NavigationBar";
import NewProjectDataSets from "./NewProjectDataSets";
import {
    useDispatch,
} from "react-redux";
import {
    getUserRequest,
} from "../actions/user.js";

/**
 * The App Component
 * @return {JSX} the App Component
 */
export default () => {
    const dispatch = useDispatch();
    dispatch(getUserRequest());

    /*
    const vantaRef = useRef(null);
    const [vantaEffect, setVantaEffect] = useState(0);

    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(FOG({
                el: vantaRef.current,
                mouseControls: false,
                touchControls: false,
                minHeight: 1200.00,
                minWidth: 200.00,
                highlightColor: "#ffffff",
                midtoneColor: "#fafafa",
                lowlightColor: "#efefef",
                baseColor: "#e1e1e1",
                speed: 2.40,
                zoom: 2.90,
            }));
        };

        return () => {
            if (vantaEffect) {
                vantaEffect.destroy();
            }
        };
    }, [vantaEffect]);
    */

    return (
        <div /* ref={vantaRef}*/ >
            <Alert />
            <Canvas />
            <CreateAccount />
            <LoadProjectDialog />
            <Login />
            <Modal />
            <NavigationBar />
            <NewProjectDataSets />
        </div>
    );
};
