import React, {
    useEffect, useState,
} from "react";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    setCreateAccountDialogOpen, setLoginDialogClosed,
} from "../actions/dialog";
import {
    Button, Dialog, Slide,
} from "@material-ui/core";
import {
    StyledDialogTitle, StyledDialogContent, StyledDialogContentText, StyledDialogActions,
}
    from "../styles/dialog";
import input from "../sharedComponents/DialogTextField";
import {
    loginUserRequest,
} from "../actions/user.js";

const fieldBlankText = "You can't leave this field blank.";

const SlideRightTransition = React.forwardRef((props, ref) => {
    return <Slide direction="right" ref={ref} {...props} />;
});

/**
 * The Login Component
 * @return {JSX} the Login Component
 */
export default () => {
    const dialogOpen = useSelector((state) => {
        return state.dialogs.loginDialogOpen;
    });
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailHelperText, setEmailHelperText] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordHelperText, setPasswordHelperText] = useState("");

    const [message, setMessage] = useState("");

    const [loggingIn, setLoggingIn] = useState(false);

    const user = useSelector((state) => {
        return state.user;
    });
    useEffect(() => {
        if (loggingIn) {
            const {
                status, message,
            } = user;
            if (status === 200) {
                resetFields();
                dispatch(setLoginDialogClosed());
            } else if (status === 400) {
                setPasswordError(true);
                setPasswordHelperText(message);
            } else if (status === 404) {
                setEmailError(true);
                setEmailHelperText(message);
            } else {
                setMessage(message);
            }

            setLoggingIn(false);
        }
    }, [user]);

    /**
     * Reset the message at the bottom of the form, every time any of the text fields change
     */
    useEffect(() => {
        if (message.length) {
            setMessage("");
        }
    }, [email, password]);

    /**
     * Reset the email error variables every time the email field changes
     */
    useEffect(() => {
        if (emailError) {
            setEmailError(false);
            setEmailHelperText("");
        }
    }, [email]);

    /**
     * Reset the password error variables every time the password field changes
     */
    useEffect(() => {
        if (passwordError) {
            setPasswordError(false);
            setPasswordHelperText("");
        }
    }, [password]);

    /**
     * Attempt to log the user in via the provided information
     */
    const login = () => {
        resetErrors();

        if (!email.length) {
            setEmailError(true);
            setEmailHelperText(fieldBlankText);
        }
        if (!password.length) {
            setPasswordError(true);
            setPasswordHelperText(fieldBlankText);
        }
        if (!email.length || !password.length) {
            return;
        }

        const params = {
            email: email,
            password: password,
        };

        setLoggingIn(true);
        dispatch(loginUserRequest(params));
    };

    /**
     * Detects changes to the email field and updates the state variable
     * @param {Object} event the event that is triggered every time the email field is typed in to
     */
    const updateEmail = (event) => {
        setEmail(event.target.value);
    };

    /**
     * Detects changes to the password field and updates the state variable
     * @param {Object} event the event that is triggered every time the password field is typed in to
     */
    const updatePassword = (event) => {
        setPassword(event.target.value);
    };

    /**
     * Reset all the error variables
     */
    const resetErrors = () => {
        setEmailError(false);
        setEmailHelperText("");

        setPasswordError(false);
        setPasswordHelperText("");
    };

    /**
     * Reset all text fields and all error variables
     */
    const resetFields = () => {
        setEmail("");
        setPassword("");
        setMessage("");
        resetErrors();
    };

    /**
     * Go to the create account dialog
     */
    const openCreateAccountDialog = () => {
        dispatch(setLoginDialogClosed());
        dispatch(setCreateAccountDialogOpen());
        resetFields();
    };

    return (
        <Dialog
            TransitionComponent={SlideRightTransition}
            maxWidth={"xs"}
            fullWidth={true}
            open={dialogOpen}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <StyledDialogTitle>Sign In</StyledDialogTitle>
            <StyledDialogContent>
                {input("Email", updateEmail, "email", email, emailError, emailHelperText)}
                {input("Password", updatePassword, "password", password, passwordError, passwordHelperText)}
            </StyledDialogContent>
            <StyledDialogContentText>
                {message}
            </StyledDialogContentText>
            <StyledDialogActions>
                <Button onClick={login} variant="outlined">
                    Sign In
                </Button>
                <Button onClick={openCreateAccountDialog} color="primary">
                    Request account
                </Button>
            </StyledDialogActions>
        </Dialog>
    );
};
