import {
    SET_BUILD_NEW_PROJECT_DIALOG_OPEN, SET_BUILD_NEW_PROJECT_DIALOG_CLOSED,
    SET_CONFIRM_DELETE_DATA_SETS_DIALOG_OPEN, SET_CONFIRM_DELETE_DATA_SETS_DIALOG_CLOSED,
    SET_CREATE_ACCOUNT_DIALOG_OPEN, SET_CREATE_ACCOUNT_DIALOG_CLOSED,
    SET_LOAD_PROJECT_DIALOG_OPEN, SET_LOAD_PROJECT_DIALOG_CLOSED,
    SET_LOGIN_DIALOG_OPEN, SET_LOGIN_DIALOG_CLOSED,
    SET_NEW_PROJECT_DATA_SETS_DIALOG_OPEN, SET_NEW_PROJECT_DATA_SETS_DIALOG_CLOSED,
    SET_NEW_PROJECT_METADATA_DIALOG_OPEN, SET_NEW_PROJECT_METADATA_DIALOG_CLOSED,
    SET_NEW_DATA_SET_DIALOG_OPEN, SET_NEW_DATA_SET_DIALOG_CLOSED,
} from "../constants/action-types";

const initialState = {
    buildNewProjectDialogOpen: false,
    confirmDeleteDataSetsDialogOpen: false,
    createAccountDialogOpen: false,
    loadProjectDialogOpen: false,
    loginDialogOpen: false,
    newProjectDataSetsDialogOpen: false,
    newProjectMetadataDialogOpen: false,
    newDataSetDialogOpen: false,
};

const dialogs = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUILD_NEW_PROJECT_DIALOG_OPEN:
            return {
                ...state,
                buildNewProjectDialogOpen: true,
            };

        case SET_BUILD_NEW_PROJECT_DIALOG_CLOSED:
            return {
                ...state,
                buildNewProjectDialogOpen: false,
            };

        case SET_CONFIRM_DELETE_DATA_SETS_DIALOG_OPEN:
            return {
                ...state,
                confirmDeleteDataSetsDialogOpen: true,
            };

        case SET_CONFIRM_DELETE_DATA_SETS_DIALOG_CLOSED:
            return {
                ...state,
                confirmDeleteDataSetsDialogOpen: false,
            };

        case SET_CREATE_ACCOUNT_DIALOG_OPEN:
            return {
                ...state,
                createAccountDialogOpen: true,
            };

        case SET_CREATE_ACCOUNT_DIALOG_CLOSED:
            return {
                ...state,
                createAccountDialogOpen: false,
            };

        case SET_LOAD_PROJECT_DIALOG_OPEN:
            return {
                ...state,
                loadProjectDialogOpen: true,
            };

        case SET_LOAD_PROJECT_DIALOG_CLOSED:
            return {
                ...state,
                loadProjectDialogOpen: false,
            };

        case SET_LOGIN_DIALOG_OPEN:
            return {
                ...state,
                loginDialogOpen: true,
            };

        case SET_LOGIN_DIALOG_CLOSED:
            return {
                ...state,
                loginDialogOpen: false,
            };

        case SET_NEW_PROJECT_DATA_SETS_DIALOG_OPEN:
            return {
                ...state,
                newProjectDataSetsDialogOpen: true,
            };

        case SET_NEW_PROJECT_DATA_SETS_DIALOG_CLOSED:
            return {
                ...state,
                newProjectDataSetsDialogOpen: false,
            };

        case SET_NEW_PROJECT_METADATA_DIALOG_OPEN:
            return {
                ...state,
                newProjectMetadataDialogOpen: true,
            };

        case SET_NEW_PROJECT_METADATA_DIALOG_CLOSED:
            return {
                ...state,
                newProjectMetadataDialogOpen: false,
            };

        case SET_NEW_DATA_SET_DIALOG_OPEN:
            return {
                ...state,
                newDataSetDialogOpen: true,
            };

        case SET_NEW_DATA_SET_DIALOG_CLOSED:
            return {
                ...state,
                newDataSetDialogOpen: false,
            };

        default:
            return state;
    };
};

export default dialogs;
