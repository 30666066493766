import {
    SET_BUILD_NEW_PROJECT_DIALOG_OPEN, SET_BUILD_NEW_PROJECT_DIALOG_CLOSED,
    SET_CONFIRM_DELETE_DATA_SETS_DIALOG_OPEN, SET_CONFIRM_DELETE_DATA_SETS_DIALOG_CLOSED,
    SET_CREATE_ACCOUNT_DIALOG_OPEN, SET_CREATE_ACCOUNT_DIALOG_CLOSED,
    SET_LOAD_PROJECT_DIALOG_OPEN, SET_LOAD_PROJECT_DIALOG_CLOSED,
    SET_LOGIN_DIALOG_OPEN, SET_LOGIN_DIALOG_CLOSED,
    SET_NEW_PROJECT_DATA_SETS_DIALOG_OPEN, SET_NEW_PROJECT_DATA_SETS_DIALOG_CLOSED,
    SET_NEW_PROJECT_METADATA_DIALOG_OPEN, SET_NEW_PROJECT_METADATA_DIALOG_CLOSED,
    SET_NEW_DATA_SET_DIALOG_OPEN, SET_NEW_DATA_SET_DIALOG_CLOSED,
} from "../constants/action-types";

export const setBuildNewProjectDialogOpen = () => {
    return {
        type: SET_BUILD_NEW_PROJECT_DIALOG_OPEN,
    };
};

export const setBuildNewProjectDialogClosed = () => {
    return {
        type: SET_BUILD_NEW_PROJECT_DIALOG_CLOSED,
    };
};

export const setConfirmDeleteDataSetsDialogOpen = () => {
    return {
        type: SET_CONFIRM_DELETE_DATA_SETS_DIALOG_OPEN,
    };
};

export const setConfirmDeleteDataSetsDialogClosed = () => {
    return {
        type: SET_CONFIRM_DELETE_DATA_SETS_DIALOG_CLOSED,
    };
};

export const setCreateAccountDialogOpen = () => {
    return {
        type: SET_CREATE_ACCOUNT_DIALOG_OPEN,
    };
};

export const setCreateAccountDialogClosed = () => {
    return {
        type: SET_CREATE_ACCOUNT_DIALOG_CLOSED,
    };
};

export const setLoadProjectDialogOpen = () => {
    return {
        type: SET_LOAD_PROJECT_DIALOG_OPEN,
    };
};

export const setLoadProjectDialogClosed = () => {
    return {
        type: SET_LOAD_PROJECT_DIALOG_CLOSED,
    };
};

export const setLoginDialogOpen = () => {
    return {
        type: SET_LOGIN_DIALOG_OPEN,
    };
};

export const setLoginDialogClosed = () => {
    return {
        type: SET_LOGIN_DIALOG_CLOSED,
    };
};

export const setNewProjectDataSetsDialogOpen = () => {
    return {
        type: SET_NEW_PROJECT_DATA_SETS_DIALOG_OPEN,
    };
};

export const setNewProjectDataSetsDialogClosed = () => {
    return {
        type: SET_NEW_PROJECT_DATA_SETS_DIALOG_CLOSED,
    };
};

export const setNewProjectMetadataDialogOpen = () => {
    return {
        type: SET_NEW_PROJECT_METADATA_DIALOG_OPEN,
    };
};

export const setNewProjectMetadataDialogClosed = () => {
    return {
        type: SET_NEW_PROJECT_METADATA_DIALOG_CLOSED,
    };
};

export const setNewDataSetDialogOpen = () => {
    return {
        type: SET_NEW_DATA_SET_DIALOG_OPEN,
    };
};

export const setNewDataSetDialogClosed = () => {
    return {
        type: SET_NEW_DATA_SET_DIALOG_CLOSED,
    };
};
