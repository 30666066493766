import React from "react";
import {
    Select, FormControl, InputLabel, MenuItem,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";

const StyledFormControl = withStyles((theme) => {
    return {
        root: {
            width: "200px",
        },
    };
})(FormControl);

/**
 * The Select Component
 * @param {Object} props data from the parent component
 * @return {JSX} the Select Component
 */
export default (props) => {
    const {
        id, show, key, label, items, emptyKeys,
    } = props.metadata;

    const {
        columnIndex, columns, setColumns,
    } = props;

    /**
     * Updates the data set's metadata when a new menu item is chosen from a select box
     * @param {Object} event - the event that is fired when a new menu item is chosen from the select box
     */
    const updateDataSetMetadata = (event) => {
        setColumns([...columns].map((columnMetadata, index) => {
            if (index === columnIndex) {
                let keysToReset = {};
                // reset the value in any elements that rely on a value from this select box
                if (emptyKeys) {
                    keysToReset = emptyKeys();
                }

                return {
                    ...columnMetadata,
                    [key]: event.target.value.length ? event.target.value : "",
                    ...keysToReset,
                };
            } else {
                return {
                    ...columnMetadata,
                };
            }
        }));
    };

    /**
     * Render the menu items in the select box
     * @return {JSX} the menu items in the select box
     */
    const getMenuItems = () => {
        return items.map((item, itemIndex) => {
            return (
                <MenuItem
                    key={itemIndex}
                    value={item}
                    // eventually delete this
                    disabled={item === "DMS"}
                >
                    {item}
                </MenuItem>
            );
        });
    };

    return (show === undefined) || show ?
        <>
            &emsp;&emsp;&emsp;

            <StyledFormControl>
                <InputLabel id={id}>{label}</InputLabel>
                <Select
                    labelId={id}
                    value={columns[columnIndex][key] || ""}
                    onChange={(event) => {
                        updateDataSetMetadata(event);
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {getMenuItems()}
                </Select>
            </StyledFormControl>
        </> :
        null;
};
