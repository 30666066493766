import React from "react";
import {
    Checkbox, FormControlLabel,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";

const StyledCheckbox = withStyles((theme) => {
    return {
        root: {
            color: "darkgray !important",
        },
    };
})(Checkbox);

const StyledFormControlLabel = withStyles((theme) => {
    return {
        root: {
            position: "relative",
            top: "10px",
        },
    };
})(FormControlLabel);

/**
 * The Checkbox Component
 * @param {Object} props data from the parent component
 * @return {JSX} the Checkbox Component
 */
export default (props) => {
    const {
        key, label, emptyKeys,
    } = props.metadata;

    const {
        columnIndex, columns, setColumns,
    } = props;

    /**
     * Updates the data set's metadata when a checkbox is clicked
     * @param {Object} event - the event that is fired when a checkbox is clicked
     */
    const updateDataSetMetadata = (event) => {
        setColumns([...columns].map((columnMetadata, index) => {
            if (index === columnIndex) {
                let keysToReset = {};
                // reset the value in any elements that rely on a value from this checkbox
                if (emptyKeys) {
                    keysToReset = emptyKeys();
                }

                return {
                    ...columnMetadata,
                    [key]: event.target.checked,
                    ...keysToReset,
                };
            } else {
                return {
                    ...columnMetadata,
                };
            }
        }));
    };

    return (
        <>
            &emsp;&emsp;&emsp;

            <StyledFormControlLabel
                label={label}
                control={
                    <StyledCheckbox
                        color="primary"
                        checked={columns[columnIndex][key] || false}
                        onChange={(event) => {
                            updateDataSetMetadata(event);
                        }}
                    />
                }
            />
        </>
    );
};
