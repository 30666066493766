import React, {
    useState, useEffect, forwardRef,
} from "react";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    makeStyles, withStyles,
} from "@material-ui/core/styles";
import dateString from "../sharedFunctions/dateString";
import {
    API_URL,
} from "../../config.js";
import {
    loadModel, clearModel,
} from "../actions/index";
import {
    setLoadProjectDialogClosed,
} from "../actions/dialog";
import {
    deleteProjectRequest,
} from "../actions/projects";
import {
    Button, IconButton, Slide,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Table, TableHead, TableBody, TableRow, TableCell,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

const SlideLeftTransition = forwardRef((props, ref) => {
    return <Slide direction="left" ref={ref} {...props} />;
});

const StyledColumnHeadersTableRow = withStyles((theme) => {
    return {
        root: {
            borderBottom: "5px solid white",
        },
    };
})(TableRow);

const StyledDataTableRow = withStyles((theme) => {
    return {
        root: {
            "&:not(:first-child)": {
                borderTop: "3px solid white",
            },
            "&:nth-of-type(odd)": {
                backgroundColor: "gainsboro",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "whitesmoke",
            },
        },
    };
})(TableRow);

const commonTableCellStyles = {
    "userSelect": "none",
    "padding": "0px 16px",
    "overflowX": "hidden",
    "whiteSpace": "nowrap",
    "textOverflow": "ellipsis",
    "borderBottom": "none",
    "&:first-child": {
        borderRight: "2px solid white",
    },
};

const StyledTableColumnCell = withStyles((theme) => {
    return {
        root: {
            ...commonTableCellStyles,
            color: "white",
            textAlign: "center",
            position: "relative",
            backgroundColor: "darkgray",
            height: "45px",
            fontWeight: "bold",
        },
    };
})(TableCell);

const StyledTableRowCell = withStyles((theme) => {
    return {
        root: {
            ...commonTableCellStyles,
            height: "25px",
        },
    };
})(TableCell);

const commonBoxStyles = {
    border: "black solid 2px",
    height: "400px",
};

const scrollStyles = {
    "overflow": "auto",

    "&::-webkit-scrollbar": {
        border: "solid 1px darkgray",
    },

    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgray",
    },
};

const leftBoxWidth = {
    width: "35%",
};

const rightBoxWidth = {
    width: "65%",
};

const boxTitle = {
    padding: "5px",
    fontSize: "19px",
};

/**
 * Styles to be used as classes in this component
 */
const useStyles = makeStyles({
    leftBoxTitle: {
        ...leftBoxWidth,
        ...boxTitle,
    },
    rightBoxTitle: {
        ...rightBoxWidth,
        ...boxTitle,
    },
    leftBox: {
        ...commonBoxStyles,
        ...scrollStyles,
        ...leftBoxWidth,
    },
    rightBox: {
        ...commonBoxStyles,
        ...rightBoxWidth,
    },
    boxContent: {
        padding: "15px",
        height: "100%",
        boxSizing: "border-box",
    },
    scroll: {
        ...scrollStyles,
    },
    positionRelative: {
        position: "relative",
    },
    iconButton: {
        position: "absolute",
        right: "1%",
        top: "2%",
    },
    textAlignCenter: {
        textAlign: "center",
    },
    dialogTitleText: {
        fontWeight: "bold",
        fontSize: "22px",
    },
    displayFlex: {
        display: "flex",
    },
    project: {
        cursor: "pointer",
        width: "fit-content",
        lineHeight: "30px",
    },
    projectContent: {
        display: "flex",
        height: "100%",
        boxSizing: "border-box",
    },
    projectName: {
        paddingBottom: "15px",
        fontWeight: "bold",
        fontSize: "20px",
    },
    projectThumbnail: {
        width: "100%",
        height: "auto",
    },
    projectDataTablesTitle: {
        backgroundColor: "whitesmoke",
        padding: "10px 0px",
        textAlign: "center",
    },
    projectDataTableName: {
        padding: "10px 0px 5px 0px",
    },
    halfWidth: {
        width: "50%",
    },
    paddingTopFifteen: {
        paddingTop: "15px",
    },
    paddingBottomFifteen: {
        paddingBottom: "15px",
    },
    paddingBottomFive: {
        paddingBottom: "5px",
    },
    dialogActions: {
        justifyContent: "center",
    },
});

/**
 * The LoadProjectDialog Component
 * @return {JSX} the LoadProjectDialog Component
 */
export default () => {
    const classes = useStyles();

    const canvasProjectId = useSelector((state) => {
        return state.canvas;
    }).projectId;

    const dialogOpen = useSelector((state) => {
        return state.dialogs.loadProjectDialogOpen;
    });
    const userProjects = useSelector((state) => {
        return state.projects;
    });
    const dispatch = useDispatch();

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if (userProjects.length || dialogOpen) {
            setProjects(userProjects);
        }
    }, [userProjects, dialogOpen]);

    /**
     * Get the selected project
     * @return {Object} the selected project
     */
    const getSelectedProject = () => {
        return projects.find((project) => {
            return project.selected;
        });
    };

    /**
     * Open the selected project on the konva canvas
     */
    const openProject = () => {
        dispatch(setLoadProjectDialogClosed());
        dispatch(clearModel());

        const projId = getSelectedProject().projId;
        dispatch(loadModel({
            model_url: `${API_URL}/api/model/${projId}`,
        }));
    };

    /**
     * Close this dialog
     */
    const cancel = () => {
        dispatch(setLoadProjectDialogClosed());
    };

    /**
     * Get the total number of records from the selected project
     * @param {Object} project - the selected project
     * @return {Number} the total number of records from the selected project
     */
    const getTotalRecords = (project) => {
        return project.dt1.recordCount + (project.dt2 ? project.dt2.recordCount : 0);
    };

    /**
     * Delete the selected project
     * @param {String} projId - the project's unique ID
     */
    const deleteProject = (projId) => {
        const clearCanvas = canvasProjectId === projId;
        dispatch(deleteProjectRequest({
            projId,
            clearCanvas,
        }));
    };

    return (
        <Dialog
            TransitionComponent={SlideLeftTransition}
            maxWidth={"xl"}
            fullWidth={true}
            open={dialogOpen}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            className={classes.positionRelative}
        >
            <IconButton onClick={cancel} className={classes.iconButton}>
                <CloseIcon />
            </IconButton>
            <DialogTitle className={classes.textAlignCenter}>
                <div className={classes.dialogTitleText}>Open Project</div>
            </DialogTitle>
            <DialogContent>
                <div className={classes.displayFlex}>
                    <div className={classes.leftBoxTitle}>My Projects</div>
                    &emsp;
                    <div className={classes.rightBoxTitle}>Project Details</div>
                </div>
                <div className={classes.displayFlex}>
                    <div className={classes.leftBox}>
                        <div className={classes.boxContent}>
                            {projects.map((project, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setProjects([...projects].map((project, i) => {
                                                project.selected = (i === index);
                                                return project;
                                            }));
                                        }}
                                        className={classes.project}
                                        style={{
                                            backgroundColor: project.selected ? "lightblue" : "initial",
                                        }}
                                    >
                                        {project.projName}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    &emsp;
                    <div className={classes.rightBox}>
                        <div className={classes.boxContent}>
                            {projects.map((project, index) => {
                                return project.selected ? (
                                    <div key={index} className={classes.projectContent}>
                                        <div className={classes.halfWidth}>
                                            <div className={classes.projectName}>
                                                {project.projName}
                                                <IconButton
                                                    onClick={() => {
                                                        deleteProject(project.projId);
                                                    }}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </div>
                                            <div className={classes.paddingBottomFive}>
                                                Creation On: {dateString(project.dateCreated)}
                                            </div>
                                            <div className={classes.paddingBottomFive}>
                                                Total Records: {getTotalRecords(project)}
                                            </div>
                                            <img
                                                src={`data:image/png;base64,${project.thumbnail}`}
                                                alt="Thumbnail"
                                                className={classes.projectThumbnail}
                                            />
                                        </div>
                                        <div className={`${classes.scroll} ${classes.halfWidth}`}>
                                            <div className={classes.projectDataTablesTitle}>
                                                PROJECT DATA TABLES
                                            </div>
                                            <div className={classes.projectDataTableName}>
                                                {project.dt1.name}
                                            </div>
                                            <div className={classes.paddingBottomFifteen}>
                                                Created On: {dateString(project.dt1.dateCreated)}
                                            </div>
                                            <Table>
                                                <TableHead>
                                                    <StyledColumnHeadersTableRow>
                                                        <StyledTableColumnCell>
                                                            Column Name
                                                        </StyledTableColumnCell>
                                                        <StyledTableColumnCell>
                                                            Column Type
                                                        </StyledTableColumnCell>
                                                    </StyledColumnHeadersTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {project.dt1.columnsMetadata.map((metadata, mIndex) => {
                                                        return (
                                                            <StyledDataTableRow key={mIndex}>
                                                                <StyledTableRowCell>
                                                                    {metadata.displayName}
                                                                </StyledTableRowCell>
                                                                <StyledTableRowCell>
                                                                    {metadata.type}
                                                                </StyledTableRowCell>
                                                            </StyledDataTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>

                                            {project.dt2 ? (
                                                <div className={classes.paddingTopFifteen}>
                                                    <div className={classes.projectDataTableName}>
                                                        {project.dt2.name}
                                                    </div>
                                                    <div className={classes.paddingBottomFifteen}>
                                                        Created On: {dateString(project.dt2.dateCreated)}
                                                    </div>
                                                    <Table>
                                                        <TableHead>
                                                            <StyledColumnHeadersTableRow>
                                                                <StyledTableColumnCell>
                                                                    Column Name
                                                                </StyledTableColumnCell>
                                                                <StyledTableColumnCell>
                                                                    Column Type
                                                                </StyledTableColumnCell>
                                                            </StyledColumnHeadersTableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {project.dt2.columnsMetadata.map((metadata, mIndex) => {
                                                                return (
                                                                    <StyledDataTableRow key={mIndex}>
                                                                        <StyledTableRowCell>
                                                                            {metadata.displayName}
                                                                        </StyledTableRowCell>
                                                                        <StyledTableRowCell>
                                                                            {metadata.type}
                                                                        </StyledTableRowCell>
                                                                    </StyledDataTableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={cancel} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={openProject} variant="outlined" disabled={!getSelectedProject()}>
                    Open
                </Button>
            </DialogActions>
        </Dialog>
    );
};
