export const setUnion = (setA, setB) => {
    const _union = new Set(setA);
    for (const elem of setB) {
        _union.add(elem);
    }

    return _union;
};

export const setIntersection = (setA, setB) => {
    const _intersection = new Set();
    for (const elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem);
        }
    }

    return _intersection;
};

export const setDifference = (setA, setB) => {
    const _difference = new Set(setA);
    for (const elem of setB) {
        _difference.delete(elem);
    }

    return _difference;
};

export const setSymmetricDifference = (setA, setB) => {
    const _difference = new Set(setA);
    for (const elem of setB) {
        if (_difference.has(elem)) {
            _difference.delete(elem);
        } else {
            _difference.add(elem);
        }
    }

    return _difference;
};

export const setIsSuperset = (set, subset) => {
    for (const elem of subset) {
        if (!set.has(elem)) {
            return false;
        }
    }

    return true;
};
