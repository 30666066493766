import {
    all,
} from "redux-saga/effects";
import dataSetsSagas from "./dataSets";
import modelSagas from "./api-saga";
import projectsSagas from "./projects";
import userSagas from "./user";

// eslint-disable-next-line require-jsdoc
export default function* rootSaga() {
    yield all([
        ...dataSetsSagas,
        ...modelSagas,
        ...projectsSagas,
        ...userSagas,
    ]);
};
