import {
    LOGIN_USER, LOGOUT_USER,
} from "../constants/action-types";

const initialState = {
    user: null,
    status: null,
    message: null,
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                user: action.user,
                status: action.status,
                message: action.message,
            };

        case LOGOUT_USER:
            return initialState;

        default:
            return state;
    };
};

export default user;
