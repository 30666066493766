import {
    ADD_BUILT_PROJECT,
    BUILD_PROJECT_REQUEST,
    CLEAR_ACTIVE_PROJECT,
    DELETE_PROJECT,
    DELETE_PROJECT_REQUEST,
    SET_PROJECTS,
} from "../constants/action-types";

// save/start tracking the newly built project
export const addBuiltProject = (payload) => {
    return {
        type: ADD_BUILT_PROJECT,
        payload,
    };
};

// build a project based on one or two data sets
export const buildProject = (payload) => {
    return {
        type: BUILD_PROJECT_REQUEST,
        payload,
    };
};

// clear the active project on the konva canvas
export const clearActiveProject = () => {
    return {
        type: CLEAR_ACTIVE_PROJECT,
    };
};

// delete the project the user has specified
export const deleteProject = (payload) => {
    return {
        type: DELETE_PROJECT,
        payload,
    };
};

// delete the project the user has specified
export const deleteProjectRequest = (payload) => {
    return {
        type: DELETE_PROJECT_REQUEST,
        payload,
    };
};

// save/start tracking all projects associated with the user when the web app is refreshed or when
// the user logs in
export const setProjects = (payload) => {
    return {
        type: SET_PROJECTS,
        payload,
    };
};
