import React from "react";
import {
    Circle, Rect,
} from "react-konva";

/**
 * The Node Component
 * @param {Object} props data from the parent component
 * @return {JSX} the Node Component
 */
export default (props) => {
    // const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    const {
        name, x, y, dtype, canvasConfig, index, fill, stroke, strokeWidth,
        nodeClick, nodeGroupMouseEnter, nodeGroupMouseLeave,
    } = props;

    if (dtype === "category" || dtype === "geographical") {
        return (
            <Circle
                key={index}
                name={name}
                x={x}
                y={y}
                radius={dtype === "category" ? canvasConfig.cat_mark_size : canvasConfig.map_mark_size}
                fill={fill || canvasConfig.nodeBaseColor}
                stroke={stroke || "black"}
                strokeWidth={strokeWidth || 1}
                onClick={nodeClick}
                onMouseEnter={nodeGroupMouseEnter}
                onMouseLeave={nodeGroupMouseLeave}
            />
        );
    } else {
        return (
            <Rect
                key={index}
                name={name}
                // shift rect left by half its width to center
                x={x - canvasConfig.n_t_mark_size / 2}
                // shift rect up by one quarter its height to center
                y={y - canvasConfig.n_t_mark_size / 4}
                width={canvasConfig.n_t_mark_size}
                height={canvasConfig.n_t_mark_size / 2}
                fill={fill || canvasConfig.nodeBaseColor}
                stroke={null}
                strokeWidth={0}
                // set temp, float, int nodes to be initially invisible
                visible={true}
                onClick={nodeClick}
                onMouseEnter={nodeGroupMouseEnter}
                onMouseLeave={nodeGroupMouseLeave}
            />
        );
    }
};
