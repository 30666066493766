import React, {
    useState,
} from "react";
import {
    IconButton, Menu, MenuItem,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
    makeStyles, withStyles,
} from "@material-ui/core/styles";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    logoutUserRequest,
} from "../actions/user";
import {
    setLoadProjectDialogOpen, setNewProjectDataSetsDialogOpen,
} from "../actions/dialog";

const WhiteIconButton = withStyles({
    root: {
        color: "white",
    },
})(IconButton);

/**
 * Styles to be used as classes in this component
 */
const useStyles = makeStyles((theme) => {
    return {
        flexBox: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        navBar: {
            padding: "10px 30px",
            boxShadow: "0px 2px 2px gray",
            backgroundColor: "#1a1a1a",
        },
        logo: {
            width: "48px",
            height: "48px",
        },
        title: {
            color: "#ffffff",
            font: "Noto Sans",
            fontSize: "36px",
            paddingLeft: "20px",
        },
        userInfo: {
            color: "#ffffff",
            fontSize: "16px",
            marginLeft: "auto",
        },
        avatarIconContainer: {
            width: "45px",
            height: "45px",
            backgroundColor: "gray",
            borderRadius: "25px",
            cursor: "pointer",
        },
        avatarIcon: {
            width: "25px",
            height: "25px",
        },
        firstName: {
            margin: "0px 10px",
        },
    };
});

/**
 * The NavigationBar Component
 * @return {JSX} the NavigationBar Component
 */
export default () => {
    const classes = useStyles();

    const user = useSelector((state) => {
        return state.user;
    }).user;
    const dispatch = useDispatch();

    const [profileMenuAnchorElement, setProfileMenuAnchorElement] = useState(null);
    const [wizardAnchorElement, setWizardAnchorElement] = useState(null);

    const circuitBoardImage = require("../../assets/s_64x64.png").default;
    const avatarImage = require("../../assets/avatar-icon.png").default;

    /**
     * Log the user out of the application
     */
    const logout = () => {
        handleAvatarMenuClose();
        dispatch(logoutUserRequest());
    };

    /**
     * Mount the menu to be displayed when the user clicks the profile picture button
     * @param {Object} event - the event that is triggered when the user clicks the profile picture button
     */
    const handleAvatarIconClick = (event) => {
        setProfileMenuAnchorElement(event.currentTarget);
    };

    /**
     * Unmount the menu to be displayed when the user clicks the profile picture button
     */
    const handleAvatarMenuClose = () => {
        setProfileMenuAnchorElement(null);
    };

    /**
     * Mount the menu to be displayed when the user clicks the three vertical dots icon
     * @param {Object} event - the event that is triggered when the user clicks the three vertical dots icon
     */
    const handleMoreVertIconClick = (event) => {
        setWizardAnchorElement(event.currentTarget);
    };

    /**
     * Unmount the menu to be displayed when the user clicks the three vertical dots icon
     */
    const handleMoreVertMenuClose = () => {
        setWizardAnchorElement(null);
    };

    /**
     * Open the the LoadProject dialog
     */
    const openLoadProjectDialog = () => {
        handleMoreVertMenuClose();
        dispatch(setLoadProjectDialogOpen());
    };

    /**
     * Open the NewProject dialog
     */
    const openNewProjectDialog = () => {
        handleMoreVertMenuClose();
        dispatch(setNewProjectDataSetsDialogOpen());
    };

    return (
        <>
            <div className={`${classes.navBar} ${classes.flexBox}`}>
                <img src={circuitBoardImage} className={classes.logo} alt="menu" />

                <div className={classes.title}>
                    Storybørd
                </div>

                <div className={`${classes.userInfo} ${classes.flexBox}`}>
                    {user ?
                        <>
                            <div
                                className={`${classes.avatarIconContainer} ${classes.flexBox}`}
                                onClick={handleAvatarIconClick}
                            >
                                <img src={avatarImage} alt="avatar" className={classes.avatarIcon} />
                            </div>
                            <div className={classes.firstName}>{user.firstName}</div>
                            <WhiteIconButton onClick={handleMoreVertIconClick}>
                                <MoreVertIcon />
                            </WhiteIconButton>
                        </> :
                        null
                    }
                </div>
            </div>

            <Menu
                id="profile-menu"
                anchorEl={profileMenuAnchorElement}
                keepMounted
                open={Boolean(profileMenuAnchorElement)}
                onClose={handleAvatarMenuClose}
            >
                <MenuItem onClick={handleAvatarMenuClose}>Profile</MenuItem>
                <MenuItem onClick={handleAvatarMenuClose}>My account</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>

            <Menu
                id="wizard"
                anchorEl={wizardAnchorElement}
                keepMounted
                open={Boolean(wizardAnchorElement)}
                onClose={handleMoreVertMenuClose}
            >
                <MenuItem onClick={openNewProjectDialog}>New Project</MenuItem>
                <MenuItem onClick={openLoadProjectDialog}>Open Project</MenuItem>
            </Menu>
        </>
    );
};
