import React, {
    forwardRef,
} from "react";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    Button, Dialog, Slide,
} from "@material-ui/core";
import {
    StyledDialogTitle, StyledDialogContentText, StyledDialogActions,
} from "../styles/dialog";
import {
    clearMessage,
} from "../actions/alert";

/**
 * Slide transition for this dialog
 */
const SlideRightTransition = forwardRef((props, ref) => {
    return <Slide direction="right" ref={ref} {...props} />;
});

/**
 * The Alert Component
 * @return {JSX} the Alert Component
 */
export default () => {
    const {
        type, message,
    } = useSelector((state) => {
        return state.alert;
    });
    const dispatch = useDispatch();

    /**
     * Clear the alert message in redux. This dialog will close when the alert message is set to an empty string.
     */
    const clearErrorMessage = () => {
        dispatch(clearMessage());
    };

    return (
        <Dialog
            TransitionComponent={SlideRightTransition}
            maxWidth={"xs"}
            fullWidth={true}
            open={message.length > 0}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <StyledDialogTitle>{type}</StyledDialogTitle>
            <StyledDialogContentText>{message}</StyledDialogContentText>
            <StyledDialogActions>
                <Button onClick={clearErrorMessage} variant="outlined">
                    Okay
                </Button>
            </StyledDialogActions>
        </Dialog>
    );
};
