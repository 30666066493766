import {
    API_URL,
} from "../../config.js";

export const buildProject = (params) => {
    return fetch(`${API_URL}/api/buildProject`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ...params,
        }),
        credentials: "include",
    }).then((response) => {
        return response.json();
    });
};

export const deleteProject = (params) => {
    return fetch(`${API_URL}/api/deleteProject`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ...params,
        }),
        credentials: "include",
    }).then((response) => {
        return response.json();
    });
};
