/* eslint-disable require-jsdoc */
import {
    call, fork, put, takeEvery,
} from "redux-saga/effects";
import * as types from "../constants/action-types";
import * as api from "../api/user";
import {
    setLoginDialogOpen,
} from "../actions/dialog";
import {
    clearModel,
} from "../actions/index";
import {
    setDataSets, clearDataSets,
} from "../actions/dataSets";
import {
    setProjects,
} from "../actions/projects";
import {
    loginUser, logoutUser,
} from "../actions/user";
import {
    setErrorMessage,
} from "../actions/alert";

function* getUser() {
    try {
        const result = yield call(api.getUser);
        if (result.user) {
            yield put(loginUser(result));
            yield put(setDataSets(result.dataSets));
            yield put(setProjects(result.projects));
        } else {
            yield put(setLoginDialogOpen());
        }
    } catch (error) {
        yield put(setErrorMessage("We're currently experiencing issues on our end. Please " +
            "refresh the application. If the problem persists, reach out to us directly via email."));
    }
};

function* watchGetUserRequest() {
    yield takeEvery(types.GET_USER_REQUEST, getUser);
};

function* loginUserRequest(action) {
    try {
        const result = yield call(api.loginUser, action.payload);
        yield put(loginUser(result));
        if (result.status === 200) {
            yield put(setDataSets(result.dataSets));
            yield put(setProjects(result.projects));
        }
    } catch (error) {
        yield put(setErrorMessage("We experienced an error signing you in. Please " +
            "refresh the application and try again. If the problem persists, reach out to us directly via email."));
    }
};

function* watchLoginUserRequest() {
    yield takeEvery(types.LOGIN_USER_REQUEST, loginUserRequest);
};

function* logoutUserRequest() {
    try {
        yield call(api.logoutUser);
        yield put(clearModel());
        yield put(logoutUser());
        yield put(clearDataSets());
        yield put(setLoginDialogOpen());
    } catch (error) {
        yield put(setErrorMessage("We experienced an error signing you out. Please " +
            "refresh the application and try again. If the problem persists, reach out to us directly via email."));
    }
};

function* watchLogoutUserRequest() {
    yield takeEvery(types.LOGOUT_USER_REQUEST, logoutUserRequest);
};

const userSagas = [
    fork(watchGetUserRequest),
    fork(watchLoginUserRequest),
    fork(watchLogoutUserRequest),
];

export default userSagas;
