import {
    ADD_BUILT_PROJECT, DELETE_PROJECT, SET_PROJECTS,
} from "../constants/action-types";

const initialState = [];

const projects = (state = initialState, action) => {
    switch (action.type) {
        case ADD_BUILT_PROJECT:
            return [...state, action.payload];

        case DELETE_PROJECT:
            const {
                projId,
            } = action.payload;

            return state.filter((project) => {
                return project.projId !== projId;
            });

        case SET_PROJECTS:
            return action.payload.map((project) => {
                return JSON.parse(project);
            });

        default:
            return state;
    };
};

export default projects;
