import React, {
    forwardRef, useState, useEffect,
} from "react";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    Button, Slide, Dialog, Tooltip, TextField, IconButton,
    Table, TableBody, TableCell, TableHead, TableRow,
} from "@material-ui/core";
import {
    makeStyles, withStyles,
} from "@material-ui/core/styles";

import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
    SwapVert as SwapVertIcon,
    Close as CloseIcon,
} from "@material-ui/icons";
import {
    setBuildNewProjectDialogOpen, setNewProjectMetadataDialogClosed,
} from "../../actions/dialog";
import BuildNewProjectDialog from "./BuildNewProjectDialog";
import dateString from "../../sharedFunctions/dateString";

/**
 * Slide transition for this dialog
 */
const SlideLeftTransition = forwardRef((props, ref) => {
    return <Slide direction="left" ref={ref} {...props} />;
});

const StyledColumnHeadersTableRow = withStyles((theme) => {
    return {
        root: {
            borderBottom: "5px solid white",
        },
    };
})(TableRow);

const StyledDataTableRow = withStyles((theme) => {
    return {
        root: {
            "&:not(:first-child)": {
                borderTop: "3px solid white",
            },
            "&:nth-of-type(odd)": {
                backgroundColor: "gainsboro",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "whitesmoke",
            },
        },
    };
})(TableRow);

const commonTableCellStyles = {
    userSelect: "none",
    padding: "0px 16px",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderBottom: "none",
};

const StyledTableColumnCell = withStyles((theme) => {
    return {
        root: {
            ...commonTableCellStyles,
            color: "white",
            textAlign: "center",
            position: "relative",
            backgroundColor: "darkgray",
            height: "45px",
        },
    };
})(TableCell);

const StyledTableRowCell = withStyles((theme) => {
    return {
        root: {
            ...commonTableCellStyles,
            height: "25px",
        },
    };
})(TableCell);

const StyledTextField = withStyles((theme) => {
    return {
        root: {
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
            },
        },
    };
})(TextField);

/**
 * Styles to be used as classes in this component
 */
const useStyles = makeStyles((theme) => {
    return {
        dialogTitle: {
            textAlign: "center",
            padding: "16px 24px",
            fontWeight: "bold",
            fontSize: "22px",
        },
        dataTablesMetadata: {
            padding: "16px 24px",
        },
        creationInfo: {
            paddingTop: "16px",
        },
        tableContainer: {
            position: "relative",
            padding: "8px 24px",
        },
        swapButton: {
            position: "absolute",
            right: "80px",
            bottom: "71px",
        },
        table: {
            width: "85%",
        },
        tableTitle: {
            fontSize: "22px",
        },
        buttonContainer: {
            padding: "8px 24px",
        },
        floatleft: {
            float: "left",
        },
        floatRight: {
            float: "right",
        },
        blackTextFieldText: {
            "& .MuiFormLabel-root": {
                color: "black",
            },
        },
        redTextFieldText: {
            "& .MuiFormLabel-root": {
                color: "red",
            },
        },
    };
});

/**
 * Reorders an array based on source and destination indexes
 * @param {Array} list an array to be reordered
 * @param {Number} startIndex - the index of the item in the array to be moved
 * @param {Number} destinationIndex - the index the item in the array should be moved to
 * @return {Array} the reordered columns
 */
const reorder = (list, startIndex, destinationIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(destinationIndex, 0, removed);

    return result;
};

const TABLE_CELLS_METADATA = [
    {
        fieldName: "originalFileName",
        text: "Source File",
        width: "100px",
        borderLeft: "",
        textAlign: "left",
    }, {
        fieldName: "dataTableName",
        text: "DataTable Name",
        width: "300px",
        borderLeft: "2px solid white",
        textAlign: "left",
    }, {
        fieldName: "recordCount",
        text: "Record Count",
        width: "100px",
        borderLeft: "2px solid white",
        textAlign: "right",
    }, {
        fieldName: "dateCreated",
        text: "Creation Date",
        width: "100px",
        borderLeft: "2px solid white",
        textAlign: "center",
    },
];

/**
 * The NewProjectMetadataDialog Component
 * @param {Object} props data from the parent component
 * @return {JSX} the NewProjectMetadataDialog Component
 */
export default (props) => {
    const {
        selectedDataSets,
    } = props;

    const classes = useStyles();

    const dispatch = useDispatch();
    const dialogOpen = useSelector((state) => {
        return state.dialogs.newProjectMetadataDialogOpen;
    });
    const user = useSelector((state) => {
        return state.user;
    }).user;

    const [projectName, setProjectName] = useState("");
    const [dataSets, setDataSets] = useState([]);

    useEffect(() => {
        if (!dialogOpen) {
            // clear the project name every time this dialog is closed
            setProjectName("");
        } else {
            // update the data sets to be passed to the build project dialog every time this dialog
            // is opened
            setDataSets(selectedDataSets);
        }
    }, [dialogOpen]);

    /**
     * Swap the order of the two datasets when the button is pressed
     */
    const reorderDataSets = () => {
        const newDataSets = reorder(dataSets, 0, 1);
        setDataSets(newDataSets);
    };

    /**
     * Open the build project dialog
     */
    const openBuildProjectDialog = () => {
        dispatch(setBuildNewProjectDialogOpen());
    };

    /**
     * Close this dialog
     */
    const closeNewProjectMetadataDialog = () => {
        dispatch(setNewProjectMetadataDialogClosed());
    };

    /**
     * Update the project name with the text typed into the project name textfield
     * @param {Object} event - the event that is triggered when the user types into the project name text field
     */
    const updateProjectName = (event) => {
        setProjectName(event.target.value);
    };

    return (
        <>
            <BuildNewProjectDialog
                selectedDataSets={dataSets}
                projectName={projectName}
                userFullName={user && `${user.firstName} ${user.lastName}`}
            />
            <Dialog
                TransitionComponent={SlideLeftTransition}
                maxWidth={"md"}
                fullWidth={true}
                open={dialogOpen}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <div className={classes.dialogTitle}>
                    New Project
                    <IconButton
                        onClick={closeNewProjectMetadataDialog}
                        className={classes.floatRight}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.dataTablesMetadata}>
                    <StyledTextField
                        className={projectName ? classes.blackTextFieldText : classes.redTextFieldText}
                        value={projectName}
                        onChange={updateProjectName}
                        label="Project Name"
                        variant="outlined"
                        fullWidth
                    />
                    <p className={classes.creationInfo}>
                        Created By: {user && `${user.firstName} ${user.lastName}`}
                        <br />
                        Creation Date: {dateString(new Date().getTime())}
                    </p>
                </div>
                <div className={classes.tableContainer}>
                    {dataSets.length === 2 ?
                        <Button variant="outlined" onClick={reorderDataSets} className={classes.swapButton}>
                            <SwapVertIcon />
                        </Button> :
                        null
                    }
                    <p className={classes.tableTitle}>Data Tables</p>
                    <Table className={classes.table}>
                        <TableHead>
                            <StyledColumnHeadersTableRow>
                                {TABLE_CELLS_METADATA.map((tableCellMetadata, index) => {
                                    return (
                                        <StyledTableColumnCell
                                            key={index}
                                            style={{
                                                minWidth: tableCellMetadata.width,
                                                maxWidth: tableCellMetadata.width,
                                                borderLeft: tableCellMetadata.borderLeft,
                                            }}
                                        >
                                            {tableCellMetadata.text}
                                        </StyledTableColumnCell>
                                    );
                                })}
                            </StyledColumnHeadersTableRow>
                        </TableHead>
                        <TableBody>
                            {dataSets.map((dataSet, index) => {
                                return (
                                    <StyledDataTableRow key={index}>
                                        {TABLE_CELLS_METADATA.map((tableCellMetadata, tableCellIndex) => {
                                            // the text to show in the tooltip and the table
                                            let text = dataSet[tableCellMetadata.fieldName];

                                            // if the text is the dateCreated, parse the date into
                                            // a human readable format
                                            if (tableCellMetadata.fieldName === "dateCreated") {
                                                text = dateString(text);
                                            }

                                            return (
                                                <StyledTableRowCell
                                                    key={tableCellIndex}
                                                    style={{
                                                        minWidth: tableCellMetadata.width,
                                                        maxWidth: tableCellMetadata.width,
                                                        borderLeft: tableCellMetadata.borderLeft,
                                                        textAlign: tableCellMetadata.textAlign,
                                                    }}
                                                >
                                                    <Tooltip title={text} arrow placement="left">
                                                        <span>{text}</span>
                                                    </Tooltip>
                                                </StyledTableRowCell>
                                            );
                                        },
                                        )}
                                    </StyledDataTableRow>
                                );
                            })}
                            {[...Array(4 - dataSets.length)].map((i, tableRowIndex) => {
                                return (
                                    <StyledDataTableRow key={tableRowIndex + dataSets.length}>
                                        {TABLE_CELLS_METADATA.map((tableCellMetadata, tableCellIndex) => {
                                            return (
                                                <StyledTableRowCell
                                                    key={tableCellIndex}
                                                    style={{
                                                        minWidth: tableCellMetadata.width,
                                                        maxWidth: tableCellMetadata.width,
                                                        borderLeft: tableCellMetadata.borderLeft,
                                                    }}
                                                />
                                            );
                                        })}
                                    </StyledDataTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        variant="outlined"
                        onClick={closeNewProjectMetadataDialog}
                        className={classes.floatleft}
                    >
                        <NavigateBeforeIcon /> Back
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={openBuildProjectDialog}
                        disabled={!projectName.length}
                        className={classes.floatRight}
                    >
                        Next <NavigateNextIcon />
                    </Button>
                </div>
            </Dialog>
        </>
    );
};
