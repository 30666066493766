import React, {
    forwardRef, useEffect, useState, useCallback, useRef,
} from "react";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    Button, Dialog, TextField, Zoom, Table, TableHead, TableRow, TableBody,
    TableCell, DialogActions, Divider, InputAdornment, Checkbox,
} from "@material-ui/core";
import {
    StyledDialogTitle, StyledDialogContent,
} from "../../styles/dialog";
import {
    StyledTableContainer, StyledColumnHeadersTableRow, StyledSampleDataTableRow,
    StyledTableColumnCell, StyledTableRowCell, StyledTableButtonCell,
} from "../../styles/table";
import {
    makeStyles, withStyles,
} from "@material-ui/core/styles";
import {
    Edit as EditIcon,
    Close as CloseIcon,
    Done as DoneIcon,
} from "@material-ui/icons";
import {
    addDataSetRequest, deleteTemporaryDataSet,
} from "../../actions/dataSets";
import ColumnTypeControls from "./columnTypeControls/ColumnTypeControls";
import {
    DragDropContext, Droppable, Draggable,
} from "react-beautiful-dnd";

const MINIMUM_EDITABLE_WIDTH = 200;
const MINIMUM_TABLE_CELL_WIDTH = 75;
const MAXIMUM_TABLE_CELL_WIDTH = 500;
const TABLE_CELL_PADDING = 32;

/**
 * Zoom transition for this dialog
 */
const ZoomTransition = forwardRef((props, ref) => {
    return <Zoom direction="left" ref={ref} {...props} />;
});

/**
 * Styles to be used as classes in this component
 */
const useStyles = makeStyles({
    textAlignLeft: {
        textAlign: "left",
    },
    dataTableName: {
        width: "333px",
    },
    fileMetadata: {
        position: "relative",
        bottom: "-21px",
        textAlign: "left",
        display: "inline-block",
        fontSize: "12px",
    },
    overflowEllipses: {
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
    },
    dialogActions: {
        paddingLeft: "24px",
        paddingRight: "24px",
        marginTop: "50px",
        marginBottom: "25px",
    },
    columnControls: {
        marginTop: "50px",
    },
    unchecked: {
        opacity: "0.5",
        pointerEvents: "none",
    },
});

const StyledCheckbox = withStyles((theme) => {
    return {
        root: {
            "&.MuiCheckbox-colorSecondary": {
                color: "black",
            },
            "&.MuiCheckbox-colorSecondary:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
            "&.MuiCheckbox-colorSecondary.Mui-checked": {
                color: "black",
            },
            "&.MuiCheckbox-colorSecondary.Mui-checked:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
        },
    };
})(Checkbox);

const StyledButton = withStyles((theme) => {
    return {
        root: {
            width: "100%",
        },
    };
})(Button);

const DialogActionButton = withStyles((theme) => {
    return {
        root: {
            width: "100px",
        },
    };
})(Button);

// common styles for LeftDivider and RightDivider
const dividerStyles = {
    cursor: "col-resize",
    backgroundColor: "white",
    width: "2px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
};

const LeftDivider = withStyles((theme) => {
    return {
        root: {
            ...dividerStyles,
            left: 0,
        },
    };
})(Divider);

const RightDivider = withStyles((theme) => {
    return {
        root: {
            ...dividerStyles,
            right: 0,
        },
    };
})(Divider);

const StyledEditIcon = withStyles((theme) => {
    return {
        root: {
            cursor: "pointer",
            width: "16px",
            height: "16px",
            position: "relative",
            top: "3px",
        },
    };
})(EditIcon);

// common styles for StyledDoneIcon and StyledCloseIcon
const inputAdornmentIcons = {
    cursor: "pointer",
    color: "white",
};

const StyledDoneIcon = withStyles((theme) => {
    return {
        root: {
            ...inputAdornmentIcons,
        },
    };
})(DoneIcon);

const StyledCloseIcon = withStyles((theme) => {
    return {
        root: {
            ...inputAdornmentIcons,
        },
    };
})(CloseIcon);

const StyledTextField = withStyles((theme) => {
    return {
        root: {
            "width": "100%",
            "height": "21px",
            "verticalAlign": "middle",

            "& .MuiInputBase-input": {
                color: "white",
                padding: "0",
            },

            "& .MuiInput-underline:before": {
                borderBottomColor: "white",
            },

            "& .MuiInput-underline:hover:before": {
                borderBottomColor: "white",
            },

            "& .MuiInput-underline:after": {
                borderBottomColor: "white",
                borderWidth: "3px",
            },
        },
    };
})(TextField);

/**
 * The NewDataSetDialog Component
 * @param {Object} props data from the parent component
 * @return {JSX} the NewDataSetDialog Component
 */
export default (props) => {
    const {
        fileId, fileName, csvMetadata, showInDialog, numRows, updateDataTableDisplayName, updateCsvMetadata,
    } = props;

    const classes = useStyles();

    const dispatch = useDispatch();

    const dialogOpen = useSelector((state) => {
        return state.dialogs.newDataSetDialogOpen;
    });
    // clear all metadata related to the data set when this dialog is closed
    useEffect(() => {
        if (!dialogOpen) {
            setRecordCount(null);
            setSelectedColumnIndex(null);
            setColumns([]);
            setColumnsMetadata([]);
            setRows([]);
        }
    }, [dialogOpen]);

    const checkboxRefsArray = useRef([]);
    const columnHeaderRefsArray = useRef([]);
    const rowRefsArray = useRef([]);
    const buttonRefsArray = useRef([]);

    const [dragging, setDragging] = useState({});
    const [resizing, setResizing] = useState(false);
    const [dataTableName, setDateTableName] = useState("");
    const [recordCount, setRecordCount] = useState(null);
    const [filePath, setFilePath] = useState("");
    const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);
    const [columns, setColumns] = useState([]);
    const [columnsMetadata, setColumnsMetadata] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (columns.length && updateCsvMetadata) {
            updateCsvMetadata(columns);
        }
    }, [columns]);

    useEffect(() => {
        if (!fileName || !csvMetadata || !Object.keys(csvMetadata).length) {
            return;
        }

        setDateTableName(fileName.split(".")[0]);
        setRecordCount(csvMetadata.dtMeta.dtRecordCount);
        setFilePath(csvMetadata.dtMeta.dtURL);

        const csvMetadataColumns = csvMetadata.colMeta;
        const csvMetadataRows = csvMetadata.dtMeta.dtSample.slice(0, numRows);

        setColumns(csvMetadataColumns);
        // make a separate variable for tracking hovering and editing column headers
        setColumnsMetadata(csvMetadataColumns.map((column, index) => {
            return {
                index: index,
                hovered: false,
                editing: false,
                columnName: column.colDisplayName,
                checked: true,
            };
        }));
        setRows(csvMetadataRows);

        // every 100ms, check to see if every table cell has been loaded
        const interval = setInterval(() => {
            if (checkboxRefsArray.current && columnHeaderRefsArray.current &&
                rowRefsArray.current && buttonRefsArray.current) {
                // loop through the array of column metadata (number of columns)
                csvMetadataColumns.forEach((column, index) => {
                    // get the indexes of all table cells in a column
                    const indexes = getIndexesOfColumn(index, csvMetadataColumns.length, csvMetadataRows.length);

                    // set each table cell's min/max width to greater value of:
                    // the column header and the column type button
                    const columnHeaderWidth = columnHeaderRefsArray.current[index].
                        querySelector("div > span").offsetWidth;
                    const buttonWidth = buttonRefsArray.current[index].
                        querySelector("button > span.MuiButton-label > span").offsetWidth;
                    let newWidth = Math.max(columnHeaderWidth, buttonWidth);

                    // ensure the new width will be between the minimum and maximum allowable widths
                    newWidth = Math.min(Math.max(newWidth, MINIMUM_TABLE_CELL_WIDTH), MAXIMUM_TABLE_CELL_WIDTH);
                    reSizeColumn(indexes, `${newWidth}px`);
                });

                clearInterval(interval);
            }
        }, 100);
    }, [csvMetadata]);

    /**
     * Update the data table's name from user supplied text
     * @param {Object} event - the event that fires when the user types text into the editable data table name input
     */
    const updateDataTableName = (event) => {
        setDateTableName(event.target.value);
        if (updateDataTableDisplayName) {
            updateDataTableDisplayName(event.target.value);
        }
    };

    /**
     * Upload the new data set and it's metadata to to the server
     */
    const submit = () => {
        const clonedCsvMetadata = {
            ...csvMetadata,
        };
        clonedCsvMetadata.colMeta = [...columns];

        const params = {
            fileId: fileId,
            fileName: fileName,
            dataTableName: dataTableName,
            csvMetadata: JSON.stringify(clonedCsvMetadata),
        };

        dispatch(addDataSetRequest(params));
    };

    /**
     * Close this dialog
     */
    const closeConfirmUploadDataSetDialog = () => {
        const params = {
            filePath: filePath,
        };
        dispatch(deleteTemporaryDataSet(params));
    };

    /**
     * Update the state variable tracking which column headers are being hovered with the mouse
     * Fired on mouseEnter and mouseLeave events for the column header table cell
     * @param {Number} columnIndex - the index of the column header table cell being hovered or unhovered
     * @param {Boolean} bool - indicates whether or not the column header table cell is being hovered or unhovered
     */
    const updateHoveredColumns = (columnIndex, bool) => {
        setColumnsMetadata([...columnsMetadata].map((columnMetadata, index) => {
            if (index === columnIndex) {
                return {
                    ...columnMetadata,
                    hovered: bool,
                };
            } else {
                return {
                    ...columnMetadata,
                    hovered: false,
                };
            }
        }));
    };

    /**
     * Update the state variable tracking whether or not the column header table cell is in edit mode
     * @param {Number} columnIndex - the index of the column header table cell being put in/out of edit mode
     * @param {Boolean} editing - indicates if the column header table cell is being put in/out edit mode
     * @param {Boolean} saveColumnName - indicates if the user is saving the text in the edit mode textField
     */
    const updateEditedColumns = (columnIndex, editing, saveColumnName) => {
        const metadata = {
            editing: editing,
        };

        // if the user is exiting edit mode without saving
        if (!editing && !saveColumnName) {
            // reset the column name's text that may or may not have been edited
            metadata.columnName = columns[columnIndex].colDisplayName;
        }

        setColumnsMetadata([...columnsMetadata].map((columnMetadata, index) => {
            if (index === columnIndex) {
                return {
                    ...columnMetadata,
                    ...metadata,
                };
            } else {
                return columnMetadata;
            }
        }));
    };

    /**
     * Determine if a table cell's contents should be on the left or right side, based on the column type
     * @param {String} columnType - the column type
     * @return {Boolean} a boolean indicating if the table cell's content should be on the left or right
     */
    const tableCellTextAlignRight = (columnType) => {
        const compatibleDataTypes = ["Geocoordinates", "Counts", "Measures"];
        return compatibleDataTypes.includes(columnType) ? "right" : "initial";
    };

    /**
     * Update the column name for the state variable for temporary column metadata
     * @param {Object} event - the event that is fired when the user types into the editable column name field
     * @param {Number} index - the index of the column in the row of column header table cells
     */
    const updateColumnHeaderMetadataDisplayName = (event, index) => {
        setColumnsMetadata([...columnsMetadata].map((columnMetadata, i) => {
            if (i === index) {
                return {
                    ...columnMetadata,
                    columnName: event.target.value,
                };
            } else {
                return columnMetadata;
            }
        }));
    };

    /**
     * Update the column name for the state variable for permanent column metadata
     * @param {Number} index - the index of the column in the row of column header table cells
     */
    const updateColumnHeaderDisplayName = (index) => {
        setColumns([...columns].map((column, i) => {
            if (i === index) {
                return {
                    ...column,
                    colDisplayName: columnsMetadata[index].columnName,
                };
            } else {
                return column;
            }
        }));
    };

    /**
     * Determine the indexes of table cells in a column, from the sample data from the file
     * @param {Number} index - the column in the table
     * @param {Array} numColumns - the number of columns in the table
     * @param {Array} numRows - the number of rows in the sample data from the file
     * @return {Array} - an array of indexes for each table cell in a column
     */
    const getIndexesOfColumn = (index, numColumns, numRows) => {
        const indexes = [];

        const numRowTableCells = numColumns * numRows;

        let counter = index;
        while (counter < numRowTableCells) {
            indexes.push(counter);
            counter += numColumns;
        }

        return indexes;
    };

    /**
     * Handles the clicking and holding down of a divider when the the user want to resize a column
     * @param {Object} event - the event that is fired when a divider is clicked
     * @param {Number} index - the index of the column to be resized
     */
    const handleMouseDown = (event, index) => {
        // get the indexes of every table cell in a column
        const indexes = getIndexesOfColumn(index, columns.length, rows.length);
        // block hover events while the user is resizing a column
        setResizing(true);

        /**
         * Remove the column resizing event listeners when the user is done resizing a column
         * @param {Object} element - the element that the user had clicked to resize the column
         * @param {Array} indexes - the indexes of all table cells in a column to be resized
         */
        const handleMouseUp = (element, indexes) => {
            // unblock hover events when the user stops resizing a column
            setResizing(false);
            document.removeEventListener("mouseup", mouseUpHelper, true);
            document.removeEventListener("mousemove", mouseMoveHelper, true);
        };

        // enables the indexes to be used in the event listeners
        const mouseUpHelper = handleMouseUp.bind(null, indexes);
        const mouseMoveHelper = handleMouseMove.bind(null, indexes);

        document.addEventListener("mouseup", mouseUpHelper, true);
        document.addEventListener("mousemove", mouseMoveHelper, true);
    };

    /**
     * Handles the dragging of the mouse when a user is resizing a column
     * Returns a memoized callback. The component will only re-render if the resized column
     * is at a brand new position during this resizing.
     * @param {Array} indexes - the indexes of all table cells in a column to be resized
     * @param {Object} event - the event that is fired when the column is resized
     */
    const handleMouseMove = useCallback((indexes, event) => {
        const index = indexes[0];

        // calculate the new width of the column based on its positioning
        let newWidth =
            event.clientX -
            // TODO: 2 ?????? - for whatever reason, I need this 2 to get a correct resizing
            // - perhaps there's a border somehwhere that throws off the calculation
            TABLE_CELL_PADDING - 2 -
            columnHeaderRefsArray.current[index].getBoundingClientRect().left;

        // ensure the new width of the column falls within the minimum and maximum range for column width
        if ((newWidth >= MINIMUM_TABLE_CELL_WIDTH) && (newWidth <= MAXIMUM_TABLE_CELL_WIDTH)) {
            newWidth = `${newWidth}px`;
            // resize every table cell in a column
            reSizeColumn(indexes, newWidth);
        }
    }, []);

    /**
     * Resizes every table cell in a column
     * @param {Array} indexes - the indexes of the table cells in a column
     * @param {String} newWidth - the new width of the table cells
     */
    const reSizeColumn = (indexes, newWidth) => {
        const index = indexes[0];

        // resize the checkbox table cell
        if (!showInDialog) {
            checkboxRefsArray.current[index].style.minWidth = newWidth;
            checkboxRefsArray.current[index].style.maxWidth = newWidth;
        }

        // resize the column header table cell
        columnHeaderRefsArray.current[index].style.minWidth = newWidth;
        columnHeaderRefsArray.current[index].style.maxWidth = newWidth;

        // resize all the table cells in the sample data rows
        indexes.forEach((i) => {
            rowRefsArray.current[i].style.minWidth = newWidth;
            rowRefsArray.current[i].style.maxWidth = newWidth;
        });

        // resize the column type button table cell
        buttonRefsArray.current[index].style.minWidth = newWidth;
        buttonRefsArray.current[index].style.maxWidth = newWidth;
    };

    /**
     * Sets the padding of every table cell in a column
     * @param {Array} indexes - the indexes of the table cells in a column
     * @param {String} newPadding - the new padding of the table cells
     */
    const reSizeColumnPadding = (indexes, newPadding) => {
        const index = indexes[0];

        // the checkbox table cell should always have no padding

        // resize the column header table cell
        columnHeaderRefsArray.current[index].style.padding = newPadding;

        // resize all the table cells in the sample data rows
        indexes.forEach((i) => {
            rowRefsArray.current[i].style.padding = newPadding;
        });

        // resize the column type button table cell
        buttonRefsArray.current[index].style.padding = newPadding;
    };

    /**
     * Reorders an array based on source and destination indexes
     * @param {Array} list an array to be reordered
     * @param {Number} startIndex - the index of the item in the array to be moved
     * @param {Number} destinationIndex - the index the item in the array should be moved to
     * @return {Array} the reordered columns
     */
    const reorder = (list, startIndex, destinationIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(destinationIndex, 0, removed);

        return result;
    };

    /**
     * Collapses the table cells of the column being dragged
     * @param {Object} event - the event that is fired when the user starts dragging a table cell
     */
    const handleDragStart = (event) => {
        const sourceIndex = event.source.index;

        // save metadata about the column being dragged
        const currentWidth = columnHeaderRefsArray.current[sourceIndex].style.minWidth;
        setDragging({
            isDragging: true,
            index: sourceIndex,
            preDragWidth: currentWidth,
            preDragPadding: columnHeaderRefsArray.current[sourceIndex].style.padding,
        });

        // set the width and padding of the table cells in the column to 0px
        const indexes = getIndexesOfColumn(sourceIndex, columns.length, rows.length);
        reSizeColumn(indexes, "0px");
        reSizeColumnPadding(indexes, "0px");

        // reset the column header table cell to it's initial width, so the user can drag it
        columnHeaderRefsArray.current[sourceIndex].style.minWidth = currentWidth;
        columnHeaderRefsArray.current[sourceIndex].style.maxWidth = currentWidth;
        columnHeaderRefsArray.current[sourceIndex].style.padding = "0 16px";
    };

    /**
     * Resets the styling of the table cells of the column being dragged
     * Reorders the columns, columnMetadata, and rows
     * @param {Obejct} result - metadata about the dragging event
     */
    const handleDragEnd = (result) => {
        const {
            preDragWidth, preDragPadding,
        } = dragging;

        const sourceIndex = result.source.index;
        // could be null if dropped outside of droppable
        const destinationIndex = result.destination && result.destination.index;

        // reset the padding of the table cells in the column
        const sourceIndexes = getIndexesOfColumn(sourceIndex, columns.length, rows.length);
        reSizeColumnPadding(sourceIndexes, preDragPadding);

        // clear metadata about the column being dragged
        setDragging({});

        // dropped outside the list or
        // the draggable was never dragged away from it's original position
        if (!result.destination || (sourceIndex === destinationIndex)) {
            reSizeColumn(sourceIndexes, preDragWidth);
            return;
        }

        const reorderedColumns = reorder(columns, sourceIndex, destinationIndex);
        setColumns(reorderedColumns);

        const reorderedColumnsMetadata = reorder(columnsMetadata, sourceIndex, destinationIndex);
        setColumnsMetadata(reorderedColumnsMetadata);

        const newRows = [];
        rows.forEach((row) => {
            const reorderedRow = reorder(row, sourceIndex, destinationIndex);
            newRows.push(reorderedRow);
        });
        setRows(newRows);

        // the column was moved to the right
        // adjust the widths of all columns between the source and destination index
        if (destinationIndex > sourceIndex) {
            for (let i = sourceIndex; i < destinationIndex; i++) {
                const indexes = getIndexesOfColumn(i, columns.length, rows.length);
                const width = columnHeaderRefsArray.current[i + 1].style.minWidth;
                reSizeColumn(indexes, width);
            }
        // the column was moved to the left
        // adjust the widths of all columns between the destination and source index
        } else if (destinationIndex < sourceIndex) {
            for (let i = sourceIndex; i > destinationIndex; i--) {
                const indexes = getIndexesOfColumn(i, columns.length, rows.length);
                const width = columnHeaderRefsArray.current[i - 1].style.minWidth;
                reSizeColumn(indexes, width);
            }
        }

        // reset the width of the table cells in the column
        const destinationIndexes = getIndexesOfColumn(destinationIndex, columns.length, rows.length);
        reSizeColumn(destinationIndexes, preDragWidth);
    };

    /**
     * Render the row of checkboxes
     * @return {JSX} the row of checkboxes
     */
    const getCheckboxRow = () => {
        return (
            <TableRow>
                {columnsMetadata.map((metadata, columnIndex) => {
                    return (
                        <TableCell
                            key={columnIndex}
                            ref={(ref) => {
                                return checkboxRefsArray.current[columnIndex] = ref;
                            }}
                            style={{
                                textAlign: "center",
                                padding: "0px",
                            }}
                        >
                            {dragging.index !== columnIndex ?
                                <StyledCheckbox
                                    checked={metadata.checked}
                                    onChange={(event) => {
                                        setColumnsMetadata(columnsMetadata.map((columnMetadata, index) => {
                                            if (columnIndex === index) {
                                                columnMetadata.checked = event.target.checked;
                                            }

                                            return columnMetadata;
                                        }));

                                        updateCsvMetadata(columns.map((column, index) => {
                                            if (columnIndex === index) {
                                                column.checked = event.target.checked;
                                            }

                                            return column;
                                        }));
                                    }}
                                ></StyledCheckbox> :
                                null
                            }
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    };

    /**
     * Render the row of column headers
     * @return {JSX} the row of column headers
     */
    const getColumnHeaders = () => {
        return columns.map((column, index) => {
            const columnCellContent = (snapshot) => {
                return (
                    <>
                        {/* Don't display the left divider on the first column header table cell */}
                        {(index === 0) || (snapshot && snapshot.isDragging) ?
                            null :
                            <LeftDivider
                                orientation="vertical"
                                onMouseEnter={() => {
                                    setResizing(true);
                                }}
                                onMouseLeave={() => {
                                    setResizing(false);
                                }}
                                onMouseDown={(event) => {
                                    // while the holding the mouse down on the left divider,
                                    // the previous column header will be able to be resized
                                    return handleMouseDown(event, index - 1);
                                }}
                            />
                        }

                        {columnsMetadata[index] && columnsMetadata[index].editing ?
                        // display the editable version of the column header's name
                            <StyledTextField
                                value={columnsMetadata[index].columnName}
                                onChange={(event) => {
                                    updateColumnHeaderMetadataDisplayName(event, index);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <StyledDoneIcon
                                                onClick={() => {
                                                    const editing = false;
                                                    const saveColumnName = true;
                                                    updateEditedColumns(index, editing, saveColumnName);
                                                    updateColumnHeaderDisplayName(index);
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <StyledCloseIcon
                                                onClick={() => {
                                                    const editing = false;
                                                    const saveColumnName = false;
                                                    updateEditedColumns(index, editing, saveColumnName);
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            /> :
                        // display the NON-editable version of the column header's name
                            <div
                                className={classes.overflowEllipses}
                                style={{
                                    opacity: (dragging.index === index) || columnsMetadata[index].checked ?
                                        "initial" : "0.5",
                                }}
                                onMouseEnter={() => {
                                    // if a column header table cell is not currently being resized
                                    if (!resizing && columnsMetadata[index].checked) {
                                        // then show the edit icon on hover
                                        updateHoveredColumns(index, true);
                                    }
                                }}
                                onMouseLeave={() => {
                                    // if a column header table cell is not currently being resized
                                    if (!resizing && columnsMetadata[index].checked) {
                                        // then hide the edit icon on hover away
                                        updateHoveredColumns(index, false);
                                    }
                                }}
                            >
                                {/* if the column header table cell is being hovered with mouse,
                        then display the edit icon */}
                                {columnsMetadata[index] && columnsMetadata[index].hovered ?
                                    <>
                                        <StyledEditIcon
                                            onClick={() => {
                                                // when the user clicks the edit icon and the width of the column
                                                // is less than 200 pixels, expand the column to 200 pixels
                                                let width = columnHeaderRefsArray.current[index].style.minWidth;
                                                width = width.length ?
                                                    parseInt(width.split("px")[0]) : MINIMUM_EDITABLE_WIDTH;
                                                if (width < MINIMUM_EDITABLE_WIDTH) {
                                                    const indexes =
                                                        getIndexesOfColumn(index, columns.length, rows.length);
                                                    reSizeColumn(indexes, `${MINIMUM_EDITABLE_WIDTH}px`);
                                                }

                                                const editing = true;
                                                const saveColumnName = null;
                                                updateEditedColumns(index, editing, saveColumnName);
                                            }}
                                        />
                                &emsp;
                                    </> :
                                    null
                                }
                                <span>{column.colDisplayName}</span>
                            </div>
                        }

                        {snapshot && snapshot.isDragging ?
                            null :
                            <RightDivider
                                orientation="vertical"
                                onMouseEnter={() => {
                                    setResizing(true);
                                }}
                                onMouseLeave={() => {
                                    setResizing(false);
                                }}
                                onMouseDown={(event) => {
                                    // while the holding the mouse down on the right divider,
                                    // the column header will be able to be resized
                                    return handleMouseDown(event, index);
                                }}
                                // the very last right divider should be the same color as the background
                                // of the column header table cell instead of white, otherwise the row of
                                // column headers will appear shorter than the other rows
                                style={{
                                    backgroundColor: index === columns.length - 1 ? "darkgray" : "white",
                                }}
                            />
                        }
                    </>
                );
            };

            if (showInDialog) {
                return (
                    <StyledTableColumnCell
                        key={index}
                        ref={(ref) => {
                            columnHeaderRefsArray.current[index] = ref;
                        }}
                        style={{
                            boxShadow: dragging.index === index ? "5px 5px 15px black" : "none",
                        }}
                    >
                        {columnCellContent()}
                    </StyledTableColumnCell>
                );
            } else {
                return (
                    <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                        isDragDisabled={resizing}
                    >
                        {(provided, snapshot) => {
                            return (
                                <StyledTableColumnCell
                                    key={index}
                                    ref={(ref) => {
                                        columnHeaderRefsArray.current[index] = ref;
                                        provided.innerRef(ref);
                                    }}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                        boxShadow: dragging.index === index ? "5px 5px 15px black" : "none",
                                        ...provided.draggableProps.style,
                                    }}
                                >
                                    {columnCellContent(snapshot)}
                                </StyledTableColumnCell>
                            );
                        }}
                    </Draggable>
                );
            }
        });
    };

    /**
     * Render the rows of the sample data from the file
     * @return {JSX} the rows of the sample data from the file
     */
    const getRows = () => {
        return rows.map((row, rowIndex) => {
            return (
                <StyledSampleDataTableRow key={rowIndex}>
                    {
                        row.map((data, columnIndex) => {
                            const index = (rowIndex * columns.length) + columnIndex;

                            return (
                                <StyledTableRowCell
                                    key={index}
                                    ref={(ref) => {
                                        return rowRefsArray.current[index] = ref;
                                    }}
                                    className={!columnsMetadata[columnIndex].checked ? classes.unchecked : ""}
                                    style={{
                                        textAlign: tableCellTextAlignRight(columns[columnIndex].colType),
                                        borderLeft: ((columnIndex === 0) || (dragging.index === columnIndex)) ?
                                            "none" : "4px solid white",
                                    }}
                                >
                                    {dragging.index === columnIndex ?
                                        null : <span>{data}</span>
                                    }
                                </StyledTableRowCell>
                            );
                        })
                    }
                </StyledSampleDataTableRow>
            );
        });
    };

    /**
     * Render the row of buttons at the bottom each column
     * @return {JSX} the row of buttons at the bottom each column
     */
    const getButtons = () => {
        return columns.map((column, index) => {
            return (
                <StyledTableButtonCell
                    key={index}
                    ref={(ref) => {
                        return buttonRefsArray.current[index] = ref;
                    }}
                    className={!columnsMetadata[index].checked ? classes.unchecked : ""}
                >
                    {dragging.index === index ?
                        null :
                        <StyledButton
                            key={index}
                            variant="outlined"
                            style={{
                                color: selectedColumnIndex === index ? "white" : "initial",
                                backgroundColor: selectedColumnIndex === index ? "darkgray" : "initial",
                            }}
                            onClick={() => {
                                setSelectedColumnIndex(column.colNumber);
                            }}
                        >
                            <span className={classes.overflowEllipses}>{column.colType}</span>
                        </StyledButton>
                    }
                </StyledTableButtonCell>
            );
        });
    };

    const getTableContent = () => {
        return (
            <>
                <div className={classes.textAlignLeft}>
                    <TextField
                        className={classes.dataTableName}
                        label="Data Table Name"
                        value={dataTableName || ""}
                        onChange={updateDataTableName}
                    />
                    &emsp;&emsp;
                    <span className={classes.fileMetadata}>
                        <span>File Name: {fileName}</span>
                        <br />
                        <span>Record Count: {recordCount}</span>
                    </span>
                </div>

                <br />

                <StyledTableContainer>
                    <Table>
                        <TableHead>
                            {!showInDialog ? getCheckboxRow() : null}
                            {showInDialog ?
                                <StyledColumnHeadersTableRow>
                                    {getColumnHeaders()}
                                </StyledColumnHeadersTableRow> :
                                <DragDropContext
                                    onDragStart={handleDragStart}
                                    onDragEnd={handleDragEnd}
                                >
                                    <Droppable
                                        droppableId="droppable"
                                        direction="horizontal"
                                    >
                                        {(provided, snapshot) => {
                                            return (
                                                <StyledColumnHeadersTableRow
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {getColumnHeaders()}
                                                    {provided.placeholder}
                                                </StyledColumnHeadersTableRow>
                                            );
                                        }}
                                    </Droppable>
                                </DragDropContext>
                            }
                        </TableHead>
                        <TableBody>
                            {getRows()}
                            <TableRow>
                                {getButtons()}
                            </TableRow>
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </>
        );
    };

    const getColumnTypeControls = () => {
        return columns[selectedColumnIndex] && Object.keys(columns[selectedColumnIndex]).length ?
            <ColumnTypeControls
                column={columns[selectedColumnIndex] || {}}
                columns={columns}
                setColumns={setColumns}
                setSelectedColumnIndex={setSelectedColumnIndex}
            /> :
            null;
    };

    return showInDialog ?
        <Dialog
            maxWidth={"xl"}
            fullWidth={true}
            TransitionComponent={ZoomTransition}
            open={dialogOpen}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <StyledDialogTitle>
                New Data Table
            </StyledDialogTitle>
            <StyledDialogContent>
                {getTableContent()}
            </StyledDialogContent>
            <DialogActions className={classes.dialogActions}>
                {getColumnTypeControls()}
                <DialogActionButton
                    variant="outlined"
                    onClick={closeConfirmUploadDataSetDialog}
                >
                    Cancel
                </DialogActionButton>
                <DialogActionButton
                    variant="outlined"
                    onClick={submit}
                >
                    Load
                </DialogActionButton>
            </DialogActions>
        </Dialog> :
        <>
            <div>{getTableContent()}</div>
            <div className={classes.columnControls}>{getColumnTypeControls()}</div>
        </>;
};
