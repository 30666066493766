import {
    CLEAR_MESSAGE, SET_CONFIRMATION_MESSAGE, SET_ERROR_MESSAGE,
} from "../constants/action-types";

export const setConfirmationMessage = (message) => {
    return {
        type: SET_CONFIRMATION_MESSAGE,
        message,
    };
};

export const setErrorMessage = (message) => {
    return {
        type: SET_ERROR_MESSAGE,
        message,
    };
};

export const clearMessage = () => {
    return {
        type: CLEAR_MESSAGE,
    };
};
