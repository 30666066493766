/* eslint-disable require-jsdoc */
import {
    call, fork, put, takeEvery,
} from "redux-saga/effects";
import {
    ADD_DATA_SET_REQUEST, DELETE_DATA_SETS_REQUEST, DELETE_TEMPORARY_DATA_SET,
} from "../constants/action-types";
import * as api from "../api/dataSets";
import {
    setNewDataSetDialogClosed, setConfirmDeleteDataSetsDialogClosed,
} from "../actions/dialog";
import {
    addDataSet, deleteDataSets,
} from "../actions/dataSets";
import {
    setErrorMessage,
} from "../actions/alert";

const DELETE_DATA_SETS_ERROR = "We're currently experiencing issues on our end and were unable to delete the " +
    "data set(s). Please refresh the application and try again. If the problem persists, reach out to us directly " +
    "via email.";

function* uploadNewDataSetRequest(action) {
    try {
        const result = yield call(api.uploadNewDataSet, action.payload);
        yield put(addDataSet(result.newDataSetMetadata));
        yield put(setNewDataSetDialogClosed());
    } catch (error) {
        yield put(setErrorMessage("We're currently experiencing issues on our end and were unable to upload the " +
            "data set. Please refresh the application. If the problem persists, reach out to us directly via email."));
    }
};

function* watchUploadNewDataSetRequest() {
    yield takeEvery(ADD_DATA_SET_REQUEST, uploadNewDataSetRequest);
};

function* deleteDataSetsRequest(action) {
    try {
        const result = yield call(api.deleteDataSets, action.payload);
        if (result.status === 200) {
            yield put(deleteDataSets(action.payload));
            yield put(setConfirmDeleteDataSetsDialogClosed());
        } else {
            yield put(setErrorMessage(DELETE_DATA_SETS_ERROR));
        }
    } catch (error) {
        yield put(setErrorMessage(DELETE_DATA_SETS_ERROR));
    }
};

function* watchDeleteDataSetRequest() {
    yield takeEvery(DELETE_DATA_SETS_REQUEST, deleteDataSetsRequest);
};

function* deleteTemporaryDataSet(action) {
    const errorMessage = "We're currently experiencing issues on our end, " +
        "please refresh the application. If the problem persists, reach out to us directly via email.";
    try {
        const result = yield call(api.deleteTemporaryDataSet, action.payload);
        if (result.status === 200) {
            yield put(setNewDataSetDialogClosed());
        } else {
            yield put(setErrorMessage(errorMessage));
        }
    } catch (error) {
        yield put(setErrorMessage(errorMessage));
    }
};

function* watchDeleteTemporaryDataSet() {
    yield takeEvery(DELETE_TEMPORARY_DATA_SET, deleteTemporaryDataSet);
};

const dataSetsSagas = [
    fork(watchUploadNewDataSetRequest),
    fork(watchDeleteDataSetRequest),
    fork(watchDeleteTemporaryDataSet),
];

export default dataSetsSagas;
