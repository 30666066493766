import React from "react";
import styled from "styled-components";
import {
    Select, InputLabel, MenuItem, IconButton, FormControl,
} from "@material-ui/core";
import {
    Close as CloseIcon,
} from "@material-ui/icons";
import * as CONSTANTS from "../../../constants/columnTypes";
import {
    makeStyles, withStyles,
} from "@material-ui/core/styles";
import SelectControls from "./Select";
import CheckboxControls from "./Checkbox";
import FileInputControls from "./FileInput";

/**
 * Styles to be used as classes in this component
 */
const useStyles = makeStyles({
    positionRelative: {
        position: "relative",
    },
    columnDisplayName: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        fontWeight: "bold",
        fontStyle: "italic",
    },
});

const ColumnTypeControlsComponent = styled.div`
    width: 100%;
    position: relative;
    top: -30px;
`;

const StyledFormControl = withStyles((theme) => {
    return {
        root: {
            width: "200px",
        },
    };
})(FormControl);

/**
 * The ColumnTypeControls Component
 * @param {Object} props data from the parent component
 * @return {JSX} the ColumnTypeControls Component
 */
export default (props) => {
    const {
        column, columns, setColumns, setSelectedColumnIndex,
    } = props;

    const classes = useStyles();

    // when a column type has been changed, all the fields corresponding with the new column type
    // will need to be initialized
    const keysInitialValues = {
        "DateTimes": {
            colTimeZone: "",
        },

        "Categories": {
            "colSubtype": "",
            "colMvalDelimiter": "",
            "colValsUnique?": false,
            "colIsMultivalued?": false,
        },

        "Ordered Categories": {
            colOrderingSpec: [],
            fileName: "",
        },

        "Geocoordinates": {
            colCoordType: "",
        },

        "Measures": {
            colMeasurementSystem: "",
            colMeasurementClass: "",
            colMeasurementUnit: "",
        },
    };

    // when a column type has been changed, all the fields corresponding with the previous column type
    // will need to be removed
    const undefinedKeys = {
        "DateTimes": {
            colTimeZone: undefined,
        },

        "Categories": {
            "colSubtype": undefined,
            "colMvalDelimiter": undefined,
            "colValsUnique?": undefined,
            "colIsMultivalued?": undefined,
        },

        "Ordered Categories": {
            colOrderingSpec: [],
            fileName: "",
        },

        "Geocoordinates": {
            colCoordType: undefined,
        },

        "Measures": {
            colMeasurementSystem: undefined,
            colMeasurementClass: undefined,
            colMeasurementUnit: undefined,
        },
    };

    /**
     * Identifies when a select box should be rendered, depending on the current column type
     * Additionally, specifies metadata for each select box, including the values to populate the
     * select box, an optional condition to show/hide the select box, and optional values to reset
     * when a select menu item has been chosen
     * @param {String} columnType - represents what kind of data is in a column
     * @return {Array} metadata for the select box to be rendered
     */
    const getSelectsMetadata = (columnType) => {
        if (columnType === "DateTimes") {
            return [
                {
                    id: "dateTimeSelect",
                    key: "colTimeZone",
                    label: "DateTime",
                    items: CONSTANTS.TIME_ZONES,
                },
            ];
        } else if (columnType === "Categories") {
            return [
                {
                    id: "categorySubtypesSelect",
                    key: "colSubtype",
                    label: "Category Subtype",
                    items: CONSTANTS.CATEGORY_SUBTYPES,
                }, {
                    id: "delimitersSelect",
                    show: column["colIsMultivalued?"],
                    key: "colMvalDelimiter",
                    label: "Delimiter",
                    items: CONSTANTS.DELIMITERS,
                },
            ];
        } else if (columnType === "Geocoordinates") {
            return [
                {
                    id: `${columnType.toLowerCase()}Select`,
                    key: "colCoordType",
                    label: columnType.slice(0, -1),
                    items: CONSTANTS.GEOCOORDINATES_SUBTYPES,
                },
            ];
        } else if (columnType === "Measures") {
            return [
                {
                    id: "measurementSystemsSelect",
                    key: "colMeasurementSystem",
                    label: "System",
                    items: CONSTANTS.MEASUREMENT_SYSTEMS,
                    emptyKeys: () => {
                        return {
                            colMeasurementClass: "",
                            colMeasurementUnit: "",
                        };
                    },
                }, {
                    id: "measurementClassesImperialSelect",
                    show: column.colMeasurementSystem === "Imperial",
                    key: "colMeasurementClass",
                    label: "Class",
                    items: CONSTANTS.MEASUREMENT_CLASSES_IMPERIAL,
                    emptyKeys: () => {
                        return {
                            colMeasurementUnit: "",
                        };
                    },
                }, {
                    id: (() => {
                        if (column.colMeasurementClass === "Distance") {
                            return "measurementUnitsImperialDistanceSelect";
                        } else if (column.colMeasurementClass === "Volume") {
                            return "measurementUnitsImperialVolumeSelect";
                        } else if (column.colMeasurementClass === "Weight") {
                            return "measurementUnitsImperialWeightSelect";
                        } else {
                            return "";
                        }
                    })(),
                    show: (column.colMeasurementSystem === "Imperial") && column.colMeasurementClass.length,
                    key: "colMeasurementUnit",
                    label: "Unit",
                    items: (() => {
                        if (column.colMeasurementClass === "Distance") {
                            return CONSTANTS.MEASUREMENT_UNITS_IMPERIAL_DISTANCE;
                        } else if (column.colMeasurementClass === "Volume") {
                            return CONSTANTS.MEASUREMENT_UNITS_IMPERIAL_VOLUME;
                        } else if (column.colMeasurementClass === "Weight") {
                            return CONSTANTS.MEASUREMENT_UNITS_IMPERIAL_WEIGHT;
                        } else {
                            return [];
                        }
                    })(),
                }, {
                    id: "measurementClassesMetricSelect",
                    show: column.colMeasurementSystem === "Metric",
                    key: "colMeasurementClass",
                    label: "Class",
                    items: CONSTANTS.MEASUREMENT_CLASSES_METRIC,
                    emptyKeys: () => {
                        return {
                            colMeasurementUnit: "",
                        };
                    },
                }, {
                    id: (() => {
                        if (column.colMeasurementClass === "Distance") {
                            return "measurementUnitsMetricDistanceSelect";
                        } else if (column.colMeasurementClass === "Volume") {
                            return "measurementUnitsMetricVolumeSelect";
                        } else if (column.colMeasurementClass === "Mass") {
                            return "measurementUnitsMetricMassSelect";
                        } else {
                            return "";
                        }
                    })(),
                    show: (column.colMeasurementSystem === "Metric") && column.colMeasurementClass.length,
                    key: "colMeasurementUnit",
                    label: "Unit",
                    items: (() => {
                        if (column.colMeasurementClass === "Distance") {
                            return CONSTANTS.MEASUREMENT_UNITS_METRIC_DISTANCE;
                        } else if (column.colMeasurementClass === "Volume") {
                            return CONSTANTS.MEASUREMENT_UNITS_METRIC_VOLUME;
                        } else if (column.colMeasurementClass === "Mass") {
                            return CONSTANTS.MEASUREMENT_UNITS_METRIC_MASS;
                        } else {
                            return [];
                        }
                    })(),
                },
            ];
        } else {
            return [];
        }
    };

    /**
     * Identifies when a checkbox should be rendered, depending on the current column type
     * Additionally, specifies an optional condition to show/hide the checkbox
     * @param {String} columnType - represents what kind of data is in a column
     * @return {Array} metadata for the checkbox to be rendered
     */
    const getCheckboxesMetadata = (columnType) => {
        if (columnType === "Categories") {
            return [
                {
                    key: "colValsUnique?",
                    label: "Unique",
                }, {
                    key: "colIsMultivalued?",
                    label: "Multivalued",
                    emptyKeys: () => {
                        return {
                            colMvalDelimiter: "",
                        };
                    },
                },
            ];
        } else {
            return [];
        }
    };

    /**
     * Identifies when a file input should be rendered, depending on the current column type
     * @param {String} columnType - represents what kind of data is in a column
     * @return {Array} metadata for the file input to be rendered
     */
    const getFileInputsMetadata = (columnType) => {
        if (columnType === "Ordered Categories") {
            return [
                {
                    key: "colOrderingSpec",
                    label: "Ordering Specification File",
                },
            ];
        } else {
            return [];
        }
    };

    /**
     * Updates the column type display, where the user can update the column type metadata for each column,
     * depending on the what the new column type is
     * @param {Object} event
     */
    const updateColumnTypeDisplay = (event) => {
        const newKey = event.target.value;

        // create an object containing the fields associated with the old column type that should deleted
        // create an object containing the fields associated with the new column type that should initialized
        let keysToDelete = {};
        let keysToReset = {};
        Object.keys(undefinedKeys).forEach((key) => {
            if (newKey !== key) {
                keysToDelete = {
                    ...keysToDelete,
                    ...undefinedKeys[key],
                };
            } else {
                keysToReset = {
                    ...keysToReset,
                    ...keysInitialValues[key],
                };
            }
        });

        // delete the fields marked for deletion
        const clonedColumns = [...columns];
        Object.keys(keysToDelete).forEach((key) => {
            delete clonedColumns[column.colNumber][key];
        });

        setColumns(clonedColumns.map((columnMetadata, columnIndex) => {
            if (columnIndex === column.colNumber) {
                return {
                    ...columnMetadata,
                    // initialize the fields marked for initialization
                    ...keysToReset,
                    colType: event.target.value,
                };
            } else {
                return {
                    ...columnMetadata,
                };
            }
        }));
    };

    /**
     * Render the select box with all the column types
     * @return {JSX} the select box with all the column types
     */
    const getColumnTypes = () => {
        return CONSTANTS.COLUMN_TYPES.map((columnType, index) => {
            return (
                <MenuItem key={index} value={columnType}>{columnType}</MenuItem>
            );
        });
    };

    /**
     * Render the select boxes associated with a column type
     * @param {String} columnType a column type
     * @return {JSX} the select boxes associated with a column type
     */
    const getSelects = (columnType) => {
        return getSelectsMetadata(columnType).map((selectMetadata, index) => {
            return (
                <SelectControls
                    key={index}
                    metadata={selectMetadata}
                    columnIndex={column.colNumber}
                    columns={columns}
                    setColumns={setColumns}
                />
            );
        });
    };

    /**
     * Render the checkboxes associated with a column type
     * @param {String} columnType a column type
     * @return {JSX} the checkboxes associated with a column type
     */
    const getCheckboxes = (columnType) => {
        return getCheckboxesMetadata(columnType).map((checkboxMetadata, index) => {
            return (
                <CheckboxControls
                    key={index}
                    metadata={checkboxMetadata}
                    columnIndex={column.colNumber}
                    columns={columns}
                    setColumns={setColumns}
                />
            );
        });
    };

    /**
     * Render the file inputs associated with a column type
     * @param {String} columnType a column type
     * @return {JSX} the file inputs associated with a column type
     */
    const getFileInputs = (columnType) => {
        return getFileInputsMetadata(columnType).map((fileInputMetadata, index) => {
            return (
                <FileInputControls
                    key={index}
                    metadata={fileInputMetadata}
                    columnIndex={column.colNumber}
                    columns={columns}
                    setColumns={setColumns}
                />
            );
        });
    };

    return (
        <ColumnTypeControlsComponent>
            <div className={classes.positionRelative}>
                <IconButton onClick={setSelectedColumnIndex}>
                    <CloseIcon />
                </IconButton>

                &emsp;

                <span className={classes.columnDisplayName}>
                    {column.colDisplayName}
                </span>
            </div>

            <div>
                &emsp;

                <StyledFormControl key="columnTypeSelect">
                    <InputLabel id="columnTypeSelect">Column Data Type</InputLabel>
                    <Select
                        labelId="columnTypeSelect"
                        value={column.colType || ""}
                        onChange={(event) => {
                            updateColumnTypeDisplay(event);
                        }}
                    >
                        {getColumnTypes()}
                    </Select>
                </StyledFormControl>

                {getSelects(column.colType)}
                {getCheckboxes(column.colType)}
                {getFileInputs(column.colType)}
            </div>
        </ColumnTypeControlsComponent>
    );
};
