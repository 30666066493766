import {
    DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";

export const StyledDialogTitle = withStyles({
    // .MuiDialogTitle-root
    root: {
        textAlign: "center",
    },
})(DialogTitle);

export const StyledDialogContent = withStyles({
    // .MuiDialogContent-root
    root: {
        textAlign: "center",
    },
})(DialogContent);

export const StyledDialogContentText = withStyles({
    // .MuiDialogContentText-root
    root: {
        margin: "10px 50px",
        textAlign: "center",
    },
})(DialogContentText);

export const StyledDialogActions = withStyles({
    // .MuiDialogActions-root
    root: {
        justifyContent: "center",
    },
})(DialogActions);

export const StyledTextField = withStyles({
    // .MuiTextField-root
    root: {
        width: "250px",
    },
})(TextField);
