import {
    applyMiddleware, createStore, compose,
} from "redux";
// import { createLogger } from "redux-logger";
import rootReducer from "../reducers/index";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";

// const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    storeEnhancers(
        applyMiddleware(sagaMiddleware /* logger*/),
    ),
);

sagaMiddleware.run(rootSaga);

export default store;
