import React from "react";
import {
    Group, Image, Rect,
} from "react-konva";
import useImage from "use-image";

/**
 * The Button Component
 * @param {Object} props data from the parent component
 * @return {JSX} the Button Component
 */
export default (props) => {
    const {
        name, iconUrl, x, y,
    } = props;

    const [image] = useImage(iconUrl);

    return (
        <Group
            x={x}
            y={y}
        >
            <Rect
                x={0}
                y={0}
                width={39}
                height={39}
                cornerRadius={3}
                fill="white"
                stroke="gray"
                strokeWidth={1}
                shadowColor="gray"
                shadowOpacity={0.6}
                shadowOffsetX={1}
                shadowOffsetY={1}
                shadowBlur={1}
            />

            <Image
                name={name}
                x={5}
                y={5}
                scaleX={0.7}
                scaleY={0.7}
                image={image}
            />
        </Group>
    );
};
