import React from "react";
import {
    Line,
} from "react-konva";

/**
 * The Curve Component
 * @param {Object} props data from the parent component
 * @return {JSX} the Curve Component
 */
export default (props) => {
    const {
        curvePoints, curveColor, curveOpacity,
    } = props;

    return (
        <Line
            bezier={true}
            points={curvePoints}
            stroke={curveColor}
            opacity={curveOpacity}
            strokeWidth={1}
        />
    );
};
