import {
    TableContainer, TableRow, TableCell,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";

export const StyledTableContainer = withStyles((theme) => {
    return {
        root: {
            "&::-webkit-scrollbar": {
                border: "solid 1px darkgray",
            },

            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "darkgray",
            },
        },
    };
})(TableContainer);

export const StyledColumnHeadersTableRow = withStyles((theme) => {
    return {
        root: {
            height: "25px",
            backgroundColor: "darkgray",
            borderBottom: "5px solid white",
        },
    };
})(TableRow);

export const StyledSampleDataTableRow = withStyles((theme) => {
    return {
        root: {
            "borderTop": "3px solid white",
            "&:nth-of-type(odd)": {
                backgroundColor: "gainsboro",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "whitesmoke",
            },
        },
    };
})(TableRow);

export const styledTableCellRoot = {
    userSelect: "none",
    padding: "0px 16px",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderBottom: "none",
};

export const StyledTableColumnCell = withStyles((theme) => {
    return {
        root: () => {
            return {
                ...styledTableCellRoot,
                color: "white",
                textAlign: "center",
                position: "relative",
                backgroundColor: "darkgray",
            };
        },
    };
})(TableCell);

export const StyledTableRowCell = withStyles((theme) => {
    return {
        root: () => {
            return {
                ...styledTableCellRoot,
                // "&:not(:first-child)": {
                //     borderLeft: "4px solid white",
                // },
            };
        },
    };
})(TableCell);

export const StyledTableButtonCell = withStyles((theme) => {
    return {
        root: {
            "textAlign": "center",
            "userSelect": "none",
            "padding": "16px 0px",
            "borderBottom": "none",
            "&:not(:first-child)": {
                borderLeft: "4px solid white",
            },
        },
    };
})(TableCell);
