import {
    ADD_DATA_SET, ADD_DATA_SET_REQUEST, CLEAR_DATA_SETS, DELETE_DATA_SETS,
    DELETE_DATA_SETS_REQUEST, DELETE_TEMPORARY_DATA_SET, SET_DATA_SETS,
} from "../constants/action-types";

export const addDataSetRequest = (payload) => {
    return {
        type: ADD_DATA_SET_REQUEST,
        payload,
    };
};

export const addDataSet = (payload) => {
    return {
        type: ADD_DATA_SET,
        payload,
    };
};

export const clearDataSets = () => {
    return {
        type: CLEAR_DATA_SETS,
    };
};

export const deleteDataSetsRequest = (payload) => {
    return {
        type: DELETE_DATA_SETS_REQUEST,
        payload,
    };
};

export const deleteDataSets = (payload) => {
    return {
        type: DELETE_DATA_SETS,
        payload,
    };
};

export const deleteTemporaryDataSet = (payload) => {
    return {
        type: DELETE_TEMPORARY_DATA_SET,
        payload,
    };
};

export const setDataSets = (payload) => {
    return {
        type: SET_DATA_SETS,
        payload,
    };
};
