import React, {
    useState, useEffect, useRef,
} from "react";
import {
    Group, Layer, Rect, Stage, Line,
} from "react-konva";

import {
    API_URL,
} from "../../../config.js";

import {
    nodesSelected, clearSelections,
} from "../../actions/index";

import {
    useDispatch, useSelector,
} from "react-redux";

import {
    makeStyles,
} from "@material-ui/core/styles";

import CanvasBasemap from "./Basemap";
import CanvasButton from "./Button";
import CanvasCurve from "./Curve";
import CanvasLabel from "./Label";
import CanvasNode from "./Node";

// command or control on th keyboard
const DRAG_KEY = window.navigator.platform === "MacIntel" ? 91 : 17;

const AXIS_D_TYPES = ["category", "geographical", "int64", "float64", "datetime64[ns]"];

const CANVAS_CONFIG = {
    navBarHeight: 90,
    borderWidth: 950,
    // light gray
    nodeBaseColor: "#bcbcbc",
    // xkcd cool grey
    // nodeActiveColor: "#95a3a6",
    // xkcd sandy yellow
    nodeSelectHoverColor: "#fdee73",
    // xkcd light gray
    // nodeDeselectHoverColor: "#d8dcd6",
    // xkcd sunflower yellow
    nodeSelectedColor: "#ffda03",

    // cat_mark_size = props.config.cat_mark_size,
    // map_mark_size = props.config.map_mark_size,
    // n_t_mark_size = props.config.n_t_mark_size,
    cat_mark_size: 7,
    map_mark_size: 4,
    n_t_mark_size: 7,

    // dark teal
    dt1Color: "#056975",
    // maroon
    dt2Color: "#731A26",
    curveOpacity: 0.5,
    minScale: 0.5,
    maxScale: 2.5,

    // catLabelBaseFontColor: "#cdcdcd",
    // labelFontFamily: "sans-serif",
    // labelBgColor: "white",
    // labelBgOpacity: 0.8,

    labelRectBuffer: 5,
    labelRectColor: "white",
    labelFontFamily: "sans-serif",
    labelRectOpacity: 0.85,

    nodeLabelFontColor: "#ababab",
    nodeLabelFontStyle: "italic",

    // xkcd dark grey
    axeslabelFontColor: "#363737",
    axesLabelFontStyle: "normal",
};

const tinycolor = require("tinycolor2");
const DT1_COLOR_LIGHT = tinycolor(CANVAS_CONFIG.dt1Color).brighten(25).toString();
const DT2_COLOR_LIGHT = tinycolor(CANVAS_CONFIG.dt2Color).brighten(25).toString();

const RESET_ZOOM_BUTTON_URL = "https://static.thenounproject.com/png/873305-42.png";
const CLEAR_BUTTON_URL = "https://static.thenounproject.com/png/3260489-42.png";
// const UNDO_BUTTON_URL = "https://static.thenounproject.com/png/1624626-42.png";
// const REDO_BUTTON_URL = "https://static.thenounproject.com/png/1624629-42.png";
// const HORIZONTAL_VERTICAL_ARROWS_BUTTON_URL = "https://static.thenounproject.com/png/1624596-42.png";
// const FILTER_BUTTON_URL = "https://static.thenounproject.com/png/3255985-42.png";
// const SEARCH_BUTTON_URL = "https://static.thenounproject.com/png/415465-42.png";

/**
 * Styles to be used as classes in this component
 */
const useStyles = makeStyles({
    stage: {
        position: "absolute",
        top: "90px",
    },
});

/**
 * The Canvas Component
 * @return {JSX} the Canvas Component
 */
export default () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const canvas = useSelector((state) => {
        return state.canvas;
    });
    // this is used to reference the latest canvas in the nodes' event listeners
    const canvasRef = useRef(canvas);

    const [modelBuilt, setModelBuilt] = useState(false);
    const [isBasemapLoaded, setIsBasemapLoaded] = useState(false);

    const [baseMapUrl, setBaseMapUrl] = useState("");

    const [dragButtonDown, setDragButtonDown] = useState(false);
    const [startingPosition, setStartingPosition] = useState(null);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [nodesInDragBox, setNodesInDragBox] = useState([]);
    const [mode, setMode] = useState("");

    const konvaCanvasRef = useRef(null);
    const stageRef = useRef(null);
    const graphicsLayerRef = useRef(null);
    const backgroundRectRef = useRef(null);
    const boundingBoxRectRef = useRef(null);
    const nodeGroupRef = useRef(null);

    // run on component initialization
    useEffect(() => {
        if (konvaCanvasRef.current) {
            konvaCanvasRef.current.tabIndex = 1;
            konvaCanvasRef.current.focus();

            konvaCanvasRef.current.addEventListener("keydown", konvaCanvasKeyDown);
            konvaCanvasRef.current.addEventListener("keyup", konvaCanvasKeyUp);
            window.addEventListener("resize", resizeStage);
        }

        return () => {
            konvaCanvasRef.current.removeEventListener("keydown", konvaCanvasKeyDown);
            konvaCanvasRef.current.removeEventListener("keyup", konvaCanvasKeyUp);
            window.removeEventListener("resize", resizeStage);
        };
    }, []);

    // run every time the canvas is updated
    useEffect(() => {
        canvasRef.current = canvas;

        // kick out if model not yet fully loaded
        if (!canvas.modelLoaded) {
            // clear canvas if there is a pre-existing graphical (i.e. Konva) model
            if (modelBuilt) {
                clearCanvas();
                setModelBuilt(false);
            }

            return;
        }

        // build the model representation if a model has been loaded
        if (canvas.modelLoaded && !modelBuilt) {
            // reset stage dimensions to fit model extents
            resizeStage();

            // build base layer
            setBaseMapUrl(`${API_URL}/api/model/${canvas.metadata.project_id}/basemap`);

            // reset view & redraw
            resetView();

            setModelBuilt(true);

            return;
        }

        // refresh stage following async basemap load
        if (canvas.basemap && !isBasemapLoaded) {
            stageRef.current.draw();
            setIsBasemapLoaded(true);

            return;
        }

        // refresh view
        stageRef.current.draw();
    }, [canvas]);

    /**
     * The user has pressed the key that indicates they are dragging the bounding box to select nodes
     * @param {Object} event - the event that's triggered when the user presses a key on the keyboard
     */
    const konvaCanvasKeyDown = (event) => {
        if (event.keyCode === DRAG_KEY) {
            setDragButtonDown(true);
        } else {
            return;
        }

        event.preventDefault();
    };

    /**
     * The user has released the key that indicates they are dragging the bounding box to select nodes
     * @param {Object} event - the event that's triggered when the user releases a key on the keyboard
     */
    const konvaCanvasKeyUp = (event) => {
        if (event.keyCode === DRAG_KEY) {
            setDragButtonDown(false);
        } else {
            return;
        }

        event.preventDefault();
    };

    /**
     * Returns the current mouse pointer position in the input layer's local coord system
     * @param {Object} layer - the layer where the mouse is
     * @return {Array} x/y coordinate of the mouse
     */
    const getRelativePointerPosition = (layer) => {
        const transform = layer.getAbsoluteTransform().copy();
        transform.invert();
        const pos = layer.getStage().getPointerPosition();
        return transform.point(pos);
    };

    /**
     * Set the new zoom. Ensure it remains in between the pre-defined min and max.
     * @param {Number} scale - the new zoom
     * @return {Number} - the new zoom, adjusted if applicable
     */
    const limitZoom = (scale) => {
        if (scale > CANVAS_CONFIG.maxScale) {
            return CANVAS_CONFIG.maxScale;
        } else if (scale < CANVAS_CONFIG.minScale) {
            return CANVAS_CONFIG.minScale;
        } else {
            return scale;
        }
    };

    //
    /**
     * Reverses the coords of the draggable bounding box, if the box has been dragged up or left
     * @param {Number} r1 - the starting position of the mouse when the dragging began
     * @param {Number} r2 - the current position of the mouse
     * @return {Object} the coordinates of the boiunding box
     */
    const reverse = (r1, r2) => {
        let r1x = r1.x;
        let r1y = r1.y;
        let r2x = r2.x;
        let r2y = r2.y;
        let d;

        if (r1x > r2x) {
            d = Math.abs(r1x - r2x);
            r1x = r2x;
            r2x = r1x + d;
        }

        if (r1y > r2y) {
            d = Math.abs(r1y - r2y);
            r1y = r2y;
            r2y = r1y + d;
        }

        return ({
            x1: r1x,
            y1: r1y,
            x2: r2x,
            y2: r2y,
        });
    };

    /**
     * Determine if a node is within the bounding box
     * @param {Object} node - a node on the canvas
     * @param {Object} bbox - the bounding box and it's corrdinates
     * @return {Boolean} a boolean representing if the node is within the bounding box
     */
    const hitCheck = (node, bbox) => {
        const nodeRect = node.getClientRect();
        const bboxRect = bbox.getClientRect();

        // corners of node bounding box
        const nodeX1 = nodeRect.x;
        const nodeY1 = nodeRect.y;
        const nodeX2 = nodeRect.x + nodeRect.width;
        const nodeY2 = nodeRect.y + nodeRect.height;

        // corners of selection bounding box
        const bboxX1 = bboxRect.x;
        const bboxY1 = bboxRect.y;
        const bboxX2 = bboxRect.x + bboxRect.width;
        const bboxY2 = bboxRect.y + bboxRect.height;

        // check for containment
        return ((nodeX1 > bboxX1) && (nodeY1 > bboxY1) && (nodeX2 < bboxX2) && (nodeY2 < bboxY2));
    };

    /**
     * Indicate the canvas has been cleared; clear the canvas state variables
     */
    const clearCanvas = () => {
        setBaseMapUrl("");
        setStartingPosition(null);
        setCurrentPosition(null);
        setDragButtonDown(false);
        setMode("PAN_ZOOM");

        stageRef.current.draw();
    };

    /**
     * Resize the Konva stage to fit the model being loaded or in response to window resizing
     */
    const resizeStage = () => {
        if ((Object.keys(canvas.metadata).length && canvas.metadata.model_width) < window.innerWidth) {
            stageRef.current.width(window.innerWidth);
        } else {
            stageRef.current.width(canvas.metadata.model_width);
        }

        if ((Object.keys(canvas.metadata).length && canvas.metadata.model_height) < window.innerHeight) {
            stageRef.current.height(window.innerHeight);
        } else {
            stageRef.current.height(canvas.metadata.model_height);
        }
    };

    /**
     * Reset canvas view to fit full model
     */
    const resetView = () => {
        const navbarHeight = CANVAS_CONFIG.navBarHeight;
        let scale = 1;

        const windowAspect = window.innerWidth / (window.innerHeight - navbarHeight);
        const modelAspect = canvas.metadata.model_width / canvas.metadata.model_height;

        // determine model scaling factor based on model & window aspect ratios
        if ((modelAspect < 1.0) && (windowAspect >= 1.0)) {
            // model is portrait & window is landscape, fit model height to window height
            scale = (window.innerHeight - navbarHeight) / canvas.metadata.model_height;
        } else if ((modelAspect > 1.0) && (windowAspect <= 1.0)) {
            // model is landscape & window is portrait, fit model width to window width
            scale = window.innerWidth / canvas.metadata.model_width;
        } else {
            // either both are landscape or both are portrait, fit according to relative aspect ratios
            if (windowAspect > modelAspect) {
                // fit model height to window height
                scale = (window.innerHeight - navbarHeight) / canvas.metadata.model_height;
            } else {
                // fit model width to window width
                scale = window.innerWidth / canvas.metadata.model_width;
            }
        }

        if (scale > 1.5) {
            scale = 1.5;
        }

        const wcX = window.innerWidth / 2;
        const wcY = (window.innerHeight - navbarHeight) / 2;

        const gcX = (canvas.metadata.model_width * scale) / 2;
        const gcY = (canvas.metadata.model_height * scale) / 2;

        graphicsLayerRef.current.x(wcX - gcX);
        graphicsLayerRef.current.y(wcY - gcY);

        graphicsLayerRef.current.scaleX(scale);
        graphicsLayerRef.current.scaleY(scale);

        stageRef.current.draw();
    };

    /**
     * The user has started dragging the mouse to select all nodes in the created bounding box
     * @param {Object} posIn - the current position of the mouse
     */
    const startDrag = (posIn) => {
        const position = {
            x: posIn.x,
            y: posIn.y,
        };
        setStartingPosition(position);
        setCurrentPosition(position);
    };

    /**
     * The user is dragging the mouse to expand or decrease the size of the bounding box
     * @param {Object} posIn - the current position of the mouse
     */
    const updateDrag = (posIn) => {
        const nodes = nodesInDragBox;

        setCurrentPosition({
            x: posIn.x,
            y: posIn.y,
        });

        const rectanglePosition = reverse(startingPosition, currentPosition);
        boundingBoxRectRef.current.x(rectanglePosition.x1);
        boundingBoxRectRef.current.y(rectanglePosition.y1);
        boundingBoxRectRef.current.width(rectanglePosition.x2 - rectanglePosition.x1);
        boundingBoxRectRef.current.height(rectanglePosition.y2 - rectanglePosition.y1);
        boundingBoxRectRef.current.visible(true);

        // look at every node
        nodeGroupRef.current.children.forEach((node) => {
            const nodeId = parseInt(node.name());

            // check if it's within the bounding box
            if (hitCheck(node, boundingBoxRectRef.current)) {
                // add node to temporary selected nodes set if it's not there
                if (!nodes.includes(nodeId)) {
                    nodes.push(nodeId);

                    // TODO: this doesn't work
                    if (!canvas.selectedNodes.has(nodeId)) {
                        // not already selected, so temporarally highlight the node
                        node.fill(CANVAS_CONFIG.nodeSelectHoverColor);
                    }
                }
            } else {
                // remove node from temporary nodes set if it's already in there
                const index = nodes.indexOf(nodeId);
                if (index > -1) {
                    nodes.splice(index, 1);
                }

                if (!canvas.selectedNodes.has(nodeId)) {
                    // revert to base color
                    node.fill(CANVAS_CONFIG.nodeBaseColor);
                }
            }
        });

        setNodesInDragBox(nodes);
    };

    /**
     * The user has begun to draw a bounding box to select nodes
     */
    const backgroundRectMouseDown = () => {
        if (!dragButtonDown) {
            return;
        }

        setMode("BBOX_SELECT");
        graphicsLayerRef.current.draggable(false);

        const position = getRelativePointerPosition(graphicsLayerRef.current);

        startDrag({
            x: position.x,
            y: position.y,
        });
    };

    /**
     * The user is drawing the bounding box to select nodes
     */
    const backgroundRectMouseMove = () => {
        if (mode === "BBOX_SELECT") {
            const position = getRelativePointerPosition(graphicsLayerRef.current);

            updateDrag({
                x: position.x,
                y: position.y,
            });

            // redraw any changes
            // TODO: this dramtically slows down then drag box and makes it unusable
            // stageRef.current.draw();
        }
    };

    //  The user has stopped drawing the bounding box to select nodes
    const backgroundRectMouseUp = () => {
        if (mode === "BBOX_SELECT") {
            setMode("PAN_ZOOM");
            boundingBoxRectRef.current.visible(false);
            graphicsLayerRef.current.draggable(true);
            dispatch(nodesSelected({
                nodeIds: nodesInDragBox,
            }));
            setNodesInDragBox([]);
        }
    };

    /**
     * Determine which button was clicked, and take appropriate action
     * @param {*} event
     */
    const guiLayerClicked = (event) => {
        const buttonName = event.target.attrs.name;
        if (buttonName === "clearButton") {
            dispatch(clearSelections());
        } else if (buttonName === "resetZoomButton") {
            resetView();
        } else {
            return;
        }
    };

    /**
     * Notify redux that a node was clicked
     * @param {Object} event - the event that's fired when a node is clicked
     */
    const nodeClick = (event) => {
        dispatch(nodesSelected({
            nodeIds: [Number(event.target.name())],
        }));
    };

    /**
     * Change the color of a node when the mouse enters it
     * @param {Object} event - the event that's triggered when the user moves the mouse over a node
     */
    const nodeMouseEnter = (event) => {
        stageRef.current.container().style.cursor = "pointer";

        const node = event.target;
        const nodeId = Number(node.name());

        // if node not currently selected or active, temporarily change node to selected state
        if (!(canvasRef.current.selectedNodes.has(nodeId) || (canvasRef.current.nodeRefCounts[nodeId] > 0))) {
            node.fill(CANVAS_CONFIG.nodeSelectHoverColor);
            node.strokeWidth(2);
        } else {
            node.strokeWidth(1);
        }

        nodeGroupRef.current.draw();
    };

    /**
     * Change the color of a node when the mouse enters it
     * @param {Object} event - the event that's triggered whenthe user moves the mouse away from a node
     */
    const nodeMouseLeave = (event) => {
        stageRef.current.container().style.cursor = "default";

        const node = event.target;
        const nodeId = Number(node.name());

        // if node not currently selected, revert to previous state
        if (!canvasRef.current.selectedNodes.has(nodeId)) {
            if (canvasRef.current.nodeRefCounts[nodeId] === 0) {
                // node is inactive
                node.fill(CANVAS_CONFIG.nodeBaseColor);
                node.strokeWidth(1);
            } else {
                // node is active
                node.strokeWidth(2);
            }
        }

        nodeGroupRef.current.draw();
    };

    /**
     * Change the zoom of the canvas
     * @param {Object} event - the event that's triggered when the user uses the mouse wheel
     * to change the zoom of the canvas
     */
    const stageWheel = (event) => {
        event.evt.preventDefault();

        const scaleBy = 0.9;
        const oldScale = graphicsLayerRef.current.scaleX();
        let newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        // constrain zooming
        newScale = limitZoom(newScale);

        graphicsLayerRef.current.scale({
            x: newScale,
            y: newScale,
        });

        const layerPointerPosition = getRelativePointerPosition(graphicsLayerRef.current);

        const correctedLayerPointerPosition = {
            x: graphicsLayerRef.current.x() + layerPointerPosition.x * newScale,
            y: graphicsLayerRef.current.y() + layerPointerPosition.y * newScale,
        };

        const mousePointTo = {
            x: correctedLayerPointerPosition.x / oldScale - graphicsLayerRef.current.x() / oldScale,
            y: correctedLayerPointerPosition.y / oldScale - graphicsLayerRef.current.y() / oldScale,
        };

        const newLayerPos = {
            x: -(mousePointTo.x - correctedLayerPointerPosition.x / newScale) * newScale,
            y: -(mousePointTo.y - correctedLayerPointerPosition.y / newScale) * newScale,
        };

        graphicsLayerRef.current.position(newLayerPos);
        stageRef.current.draw();
    };

    /**
     * Get the curves of the current project
     * @return {JSX} the curves
     */
    const getCurves = () => {
        return canvas.activeCurves.map((curveIdx, curveIndex) => {
            const numCurvesInCurves1 = canvas.curves1.length;

            // curve is from dt1
            if (curveIdx < numCurvesInCurves1) {
                return canvas.curves1[curveIdx].map((curvePoints, pointIndex) => {
                    return (
                        <CanvasCurve
                            key={`${curveIndex}${pointIndex}`}
                            curvePoints={curvePoints}
                            curveColor={CANVAS_CONFIG.dt1Color}
                            curveOpacity={CANVAS_CONFIG.curveOpacity}
                        />
                    );
                });
            // otherwise curve is from dt2
            } else {
                const newCurveIdx = curveIdx - numCurvesInCurves1;

                return canvas.curves2[newCurveIdx].map((curvePoints, pointIndex) => {
                    return (
                        <CanvasCurve
                            key={`${curveIndex}${pointIndex}`}
                            curvePoints={curvePoints}
                            curveColor={CANVAS_CONFIG.dt2Color}
                            curveOpacity={CANVAS_CONFIG.curveOpacity}
                        />
                    );
                });
            }
        });
    };

    /**
     * Get the nodes of the current project
     * @return {JSX} the nodes
     */
    const getNodes = () => {
        return canvas.nodes.map((node, index) => {
            const name = node.idx.toString();
            const x = node.coords[0];
            const y = node.coords[1];
            const dtype = node.dtype;

            let nodeAxis;
            try {
                nodeAxis = canvas.axes.find((obj) => {
                    // TODO: rename "col" to "axis_name" throughout
                    return obj.name === node.col;
                });
            } catch (err) {
                console.error("ERROR in buildNodes: ", err.message);
            }

            let fill;
            let stroke;
            let strokeWidth;
            if (canvas.nodeRefCounts[node.idx] > 0) {
                if (dtype === "category") {
                    fill = CANVAS_CONFIG.nodeBaseColor;
                    stroke = "black";
                    strokeWidth = 2;
                } else {
                    if (node.val === null) {
                        fill = CANVAS_CONFIG.nodeBaseColor;
                        stroke = "black";
                        strokeWidth = 2;
                    } else if (node.dt === "dt_1") {
                        fill = DT1_COLOR_LIGHT;
                    } else if (node.dt === "dt_2") {
                        fill = DT2_COLOR_LIGHT;
                    }
                }

                // set fill color back to "selected" color if node
                // is in the list of currently selected nodes
                if (canvas.selectedNodes.has(node.idx)) {
                    fill = CANVAS_CONFIG.nodeSelectedColor;
                }
            }

            let transformedX;
            if (nodeAxis && nodeAxis.is_shared &&
                ((dtype === "datetime64[ns]") || (dtype === "int64") || (dtype === "float64"))) {
                // shift right or left
                const halfNTMarkSize = CANVAS_CONFIG.n_t_mark_size / 2;
                transformedX = node.dt === "dt_2" ? x + halfNTMarkSize : x - halfNTMarkSize;
            }

            return (
                <CanvasNode
                    key={index}
                    name={name}
                    x={transformedX || x}
                    y={y}
                    // create a null node if needed
                    dtype={node.val === null ? "category" : dtype}
                    canvasConfig={CANVAS_CONFIG}
                    fill={fill}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                    nodeClick={nodeClick}
                    nodeGroupMouseEnter={nodeMouseEnter}
                    nodeGroupMouseLeave={nodeMouseLeave}
                />
            );
        });
    };

    /**
     * Get the node labels of the current project
     * @return {JSX} the nodes
     */
    const getNodeLabels = () => {
        return canvas.nodes.map((node, index) => {
            if (node.dtype === "category" || node.val === null) {
                const fontSize = canvas.config.cat_label_font_size;
                let fontStyle = CANVAS_CONFIG.nodeLabelFontStyle;
                let fontColor = CANVAS_CONFIG.nodeLabelFontColor;

                const nodeId = node.idx;
                if (canvas.nodeRefCounts[nodeId] > 0) {
                    fontStyle = "normal";
                    fontColor = "black";
                }

                if (canvas.selectedNodes.has(nodeId)) {
                    fontStyle = "bold";
                    fontColor = "black";
                }


                let labelText = "NULL";
                if (node.dtype === "category") {
                    if (node.val.length > canvas.config.cat_label_max_char_len) {
                        labelText = `${node.val.slice(0, canvas.config.cat_label_max_char_len)}...`;
                    } else {
                        labelText = node.val;
                    }
                }

                return (
                    <CanvasLabel
                        key={index}
                        labelText={labelText}
                        x={node.coords[0] + canvas.config.cat_label_horiz_padding}
                        y={node.coords[1]}
                        fontSize={fontSize}
                        fontStyle={fontStyle}
                        fontColor={fontColor}
                        textPosition="left"
                        canvasConfig={CANVAS_CONFIG}
                    />
                );
            }
        });
    };

    /**
     * Get the labels of the axes that appear under each axis line
     * @return {JSX} the axes' labels
     */
    const getAxisLabels = () => {
        return canvas.axes.map((axis, index) => {
            // category, geographical, int64, float64, or datetime64[ns]
            if (AXIS_D_TYPES.includes(axis.dtype)) {
                let textPosition;
                if ((axis.dtype === "category") || (axis.dtype === "geographical")) {
                    textPosition = "left";
                // otherwise will be int64, float64, or datetime64[ns]
                } else {
                    textPosition = "center";
                }

                return (
                    <CanvasLabel
                        key={index}
                        labelText={axis.label}
                        // TODO: eliminate dependency on axis.l_y and axis.l_y
                        x={axis.l_x}
                        y={axis.l_y + 20}
                        fontSize={canvas.config.axis_label_font_size}
                        fontStyle={CANVAS_CONFIG.axesLabelFontStyle}
                        fontColor={CANVAS_CONFIG.axeslabelFontColor}
                        textPosition={textPosition}
                        canvasConfig={CANVAS_CONFIG}
                    />
                );
            }
        });
    };

    /**
     * Get the axes' lines, major ticks, and minor ticks
     * @return {JSX} the axes' lines, major ticks, and minor ticks
     */
    const getAxesAnnotations = () => {
        return canvas.axes.map((axis, axisIndex) => {
            let nTAxisWidth;
            if (axis.is_shared) {
                nTAxisWidth = 2 * CANVAS_CONFIG.n_t_mark_size + 6;
            } else {
                nTAxisWidth = CANVAS_CONFIG.n_t_mark_size + 4;
            }

            const nTAxisX = axis.a_x - nTAxisWidth / 2 - canvas.config.n_t_major_tick_length - 6;

            let tickX;
            let tickY;
            let axisLength;
            let tickLength;
            let axisStep;
            let labelX;

            if ((axis.dtype === "int64") || (axis.dtype === "float64")) {
                const getTicks = () => {
                    // render tick marks with labels
                    tickX = nTAxisX;
                    tickY = axis.e_y;
                    axisLength = axis.e_y - axis.s_y;
                    tickLength = canvas.config.n_t_major_tick_length;
                    axisStep = axisLength / (axis.axis_end_val / axis.axis_tick);
                    labelX = nTAxisX;

                    const ticks = [];
                    for (let i = axis.axis_start_val; i < axis.axis_end_val + 1; i += axis.axis_tick) {
                        ticks.push(i);
                    };

                    return ticks.map((i, index) => {
                        const currentTickY = tickY;
                        tickY -= axisStep;
                        return (
                            <Group key={`intFloatTick:${index}`}>
                                <Line
                                    points={[tickX, currentTickY, tickX + tickLength, currentTickY]}
                                    stroke="black"
                                    strokeWidth={2}
                                    lineCap="square"
                                />
                                <CanvasLabel
                                    labelText={i}
                                    x={labelX}
                                    y={currentTickY}
                                    fontSize={canvas.config.n_t_label_font_size}
                                    fontStyle="normal"
                                    fontColor={CANVAS_CONFIG.axesLabelFontColor}
                                    textPosition="right"
                                    canvasConfig={CANVAS_CONFIG}
                                />
                            </Group>
                        );
                    });
                };

                return (
                    <Group key={axisIndex}>
                        <Line
                            points={[
                                nTAxisX, axis.s_y,
                                nTAxisX, axis.e_y,
                            ]}
                            stroke="black"
                            strokeWidth={2}
                            lineCap="square"
                        />
                        {getTicks()}
                    </Group>
                );
            } else if (axis.dtype === "datetime64[ns]") {
                const getMinorTicks = () => {
                    tickX = nTAxisX;
                    return axis.minor_ticks.map((tickY, minorTickIndex) => {
                        return (
                            <Line
                                key={`minorTick:${minorTickIndex}`}
                                points={[tickX, tickY, tickX + axis.minor_tickLength, tickY]}
                                stroke="black"
                                strokeWidth={1}
                                lineCap="square"
                            />
                        );
                    });
                };

                const getMajorTicks = () => {
                    const majorTickLength = axis.major_tick_length;
                    if (axis.is_shared) {
                        labelX = nTAxisX + 30;
                    } else {
                        labelX = nTAxisX + 20;
                    }

                    return Object.entries(axis.major_ticks).map(([labelText, tickY], majorTickIndex) => {
                        return (
                            <Group key={majorTickIndex}>
                                <Line
                                    points={[tickX, tickY, tickX + majorTickLength, tickY]}
                                    stroke="black"
                                    strokeWidth={2}
                                    lineCap="square"
                                />

                                {/* draw major interval label between ticks (skip last entry) */}
                                {majorTickIndex !== (Object.entries(axis.major_ticks).length - 1) ?
                                    <CanvasLabel
                                        labelText={labelText}
                                        x={labelX}
                                        y={tickY - axis.major_tick_step / 2}
                                        fontSize={axis.major_tick_font_size}
                                        fontStyle="normal"
                                        fontColor={CANVAS_CONFIG.axesLabelFontColor}
                                        textPosition="left"
                                        canvasConfig={CANVAS_CONFIG}
                                    /> :
                                    null

                                }
                            </Group>
                        );
                    });
                };

                return (
                    <Group key={axisIndex}>
                        {/* draw main vertical axis */}
                        <Line
                            points={[
                                nTAxisX, axis.s_y,
                                nTAxisX, axis.e_y,
                            ]}
                            stroke="black"
                            strokeWidth={2}
                            lineCap="square"
                        />

                        {/* draw minor ticks first */}
                        {getMinorTicks()}

                        {/* draw major ticks with labels */}
                        {getMajorTicks()}
                    </Group>
                );
            }
        });
    };

    return (
        <div ref={konvaCanvasRef}>
            <Stage
                ref={stageRef}
                width={window.innerWidth}
                height={window.innerHeight - 90}
                className={classes.stage}
                draggable={false}
                scaleX={1}
                scaleY={1}
                x={0}
                y={0}
                onWheel={stageWheel}
            >
                <Layer ref={graphicsLayerRef} name="graphicsLayer" draggable={true}>
                    <CanvasBasemap baseMapUrl={baseMapUrl} />
                    <Rect
                        ref={backgroundRectRef}
                        name="backgroundRect"
                        x={-(window.innerWidth * 10) / 2}
                        y={-(window.innerHeight * 10) / 2}
                        width={window.innerWidth * 10}
                        height={window.innerHeight * 10}
                        fill="lightgray"
                        opacity={0.0}
                        onMouseDown={backgroundRectMouseDown}
                        onMouseMove={backgroundRectMouseMove}
                        onMouseUp={backgroundRectMouseUp}
                    />
                    <Rect
                        ref={boundingBoxRectRef}
                        name="boundingBoxRect"
                        x={0}
                        y={0}
                        width={0}
                        height={0}
                        stroke="black"
                        strokeWidth={1}
                        dash={[2, 2]}
                        listening={false}

                    />
                    <Group name="curvesGroup">
                        {getCurves()}
                    </Group>
                    <Group ref={nodeGroupRef} name="nodeGroup">
                        {getNodes()}
                    </Group>
                    <Group name="catNodeLabelsGroup">
                        {getNodeLabels()}
                    </Group>
                    <Group name="axisLabelsGroup">
                        {/* gets the labels that appear under the bottom of each axis */}
                        {getAxisLabels()}
                    </Group>
                    <Group name="axisAnnotationsGroup">
                        {/* get the axis lines, ticks, and labels for each tick */}
                        {getAxesAnnotations()}
                    </Group>
                </Layer>

                <Layer name="guiLayer" draggable={false} onClick={guiLayerClicked}>
                    <CanvasButton
                        name="resetZoomButton"
                        iconUrl={RESET_ZOOM_BUTTON_URL}
                        x={30}
                        y={15}
                    />
                    <CanvasButton
                        name="clearButton"
                        iconUrl={CLEAR_BUTTON_URL}
                        x={80}
                        y={15}
                    />
                </Layer>
            </Stage>
        </div>
    );
};
