import {
    combineReducers,
} from "redux";
import alert from "./alert";
import canvas from "./canvas";
import dataSets from "./dataSets";
import dialogs from "./dialogs";
import modal from "./modal";
import projects from "./projects";
import user from "./user";

const rootReducer = combineReducers({
    alert,
    canvas,
    dataSets,
    dialogs,
    modal,
    projects,
    user,
});

export default rootReducer;
