import {
    CLEAR_MESSAGE, SET_CONFIRMATION_MESSAGE, SET_ERROR_MESSAGE,
} from "../constants/action-types";

const initialState = {
    type: "",
    message: "",
};

const alert = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONFIRMATION_MESSAGE:
            return {
                type: "Confirmation",
                message: action.message,
            };
        case SET_ERROR_MESSAGE:
            return {
                type: "Error",
                message: action.message,
            };

        case CLEAR_MESSAGE:
            return initialState;

        default:
            return state;
    };
};

export default alert;
