import React from "react";
import {
    Image,
} from "react-konva";
import useImage from "use-image";

/**
 * The Basemap Component
 * @param {Object} props data from the parent component
 * @return {JSX} the Basemap Component
 */
export default (props) => {
    const {
        baseMapUrl,
    } = props;

    const [image] = useImage(baseMapUrl);

    return (
        <Image
            name="basemap"
            x={0}
            y={0}
            scaleX={1.0}
            scaleY={1.0}
            // specify a color to draw basemap border
            stroke=""
            // also for basemap border
            strokeWidth={1}
            image={image}
        />
    );
};
