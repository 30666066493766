import React from "react";
import {
    useSelector,
} from "react-redux";
import {
    makeStyles, withStyles,
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    modal: {
        color: "white",
        backgroundColor: "black",
        opacity: "0.85",
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        overflow: "auto",
        zIndex: "2147483647",
    },
    modalContents: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    visible: {
        display: "initial",
    },
    hidden: {
        display: "none !important",
    },
});

const StyledCircularProgress = withStyles((theme) => {
    return {
        root: {
            color: "white",
        },
    };
})(CircularProgress);

/**
 * The Modal Component
 * @return {JSX} the Modal Component
 */
export default () => {
    const classes = useStyles();
    const {
        open, message,
    } = useSelector((state) => {
        return state.modal;
    });

    return (
        <div
            className={classes.modal}
            style={{
                display: open ? "initial" : "none",
            }}
        >
            <div className={classes.modalContents}>
                <StyledCircularProgress />
                <br />
                <div>{message}</div>
            </div>
        </div>
    );
};
