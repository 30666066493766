import React, {
    forwardRef, useEffect, useRef, useState,
} from "react";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    setNewDataSetDialogOpen,
    setNewProjectDataSetsDialogClosed,
    setNewProjectMetadataDialogOpen,
    setConfirmDeleteDataSetsDialogOpen,
} from "../actions/dialog";
import {
    Button, Dialog, IconButton, Slide, DialogContent, DialogActions, Tooltip,
} from "@material-ui/core";
import {
    StyledDialogTitle,
} from "../styles/dialog";
import {
    makeStyles,
} from "@material-ui/core/styles";
import {
    DataGrid,
} from "@material-ui/data-grid";
import CloseIcon from "@material-ui/icons/Close";
import NewDataSetDialog from "./DataSets/NewDataSetDialog";
import ConfirmDeleteDataSetsDialog from "./DataSets/ConfirmDeleteDataSetsDialog";
import NewProjectMetadataDialog from "./newProject/NewProjectMetadataDialog";
import datagridCss from "../styles/datagrid";
import {
    API_URL,
} from "../../config.js";
import {
    setErrorMessage,
} from "../actions/alert";
import {
    showModal, hideModal,
} from "../actions/modal.js";
import guidGenerator from "../sharedFunctions/guidGenerator";
import dateString from "../sharedFunctions/dateString";

/**
 * Slide transition for this dialog
 */
const SlideLeftTransition = forwardRef((props, ref) => {
    return <Slide direction="left" ref={ref} {...props} />;
});

/**
 * Styles to be used as classes in this component
 */
const useStyles = makeStyles({
    textAlignLeft: {
        textAlign: "left",
    },
    fileInput: {
        width: "0px",
        height: "0px",
    },
    cell: {
        textAlign: "center !important",
        pointerEvents: "none",
    },
    iconButton: {
        position: "absolute",
        right: "1%",
        top: "50%",
        transform: "translateY(-50%)",
    },
    positionRelative: {
        position: "relative",
    },
    dialogContent: {
        margin: "0 auto",
    },
    dialogActions: {
        margin: "0 auto",
        display: "initial",
        padding: "8px 24px",
    },
    cellText: {
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
        userSelect: "none",
    },
});

/**
 * The NewProjectDataSets Component
 * @return {JSX} the NewProjectDataSets Component
 */
export default () => {
    const classes = useStyles();
    const fileInputGuid = guidGenerator();
    const datagridCssClasses = datagridCss();

    const newDataSetDialogOpen = useSelector((state) => {
        return state.dialogs.newDataSetDialogOpen;
    });
    const dialogOpen = useSelector((state) => {
        return state.dialogs.newProjectDataSetsDialogOpen;
    });
    const dataSets = useSelector((state) => {
        return state.dataSets;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedDataSets([]);

        // convert some of the rows' fields from strings to integers
        const transformedRows = dataSets.map((dataSet) => {
            return {
                ...dataSet,
                dateCreated: parseInt(dataSet.dateCreated),
                recordCount: parseInt(dataSet.recordCount),
            };
        });

        // set the table height based on how many rows there are, up to a max of 10 rows per page
        const height = 110 + ((Math.min(transformedRows.length, 10) * 27) || 110);
        setTableHeight(height);
        setRows(transformedRows);
    }, [dataSets]);

    // define the initial sorting method for the table of data sets
    const initialSortModel = [
        {
            field: "dateCreated",
            sort: "desc",
        },
    ];

    const dataGrid = useRef(null);
    const fileInput = useRef(null);

    const [dataGridLength, setDataGridLength] = useState(0);

    useEffect(() => {
        if (dialogOpen) {
            const interval = setInterval(() => {
                if (dataGrid.current) {
                    setDataGridLength(dataGrid.current.getBoundingClientRect().width);
                    clearInterval(interval);
                }
            }, 0);
        }
    }, [dialogOpen]);

    const [dataSetMetadata, setDataSetMetadata] = useState({});

    const [rows, setRows] = useState([]);
    const [selectedDataSets, setSelectedDataSets] = useState([]);
    // the initial state for the sortModal needs to be saved a state variable to be read in the DOM
    // eslint-disable-next-line no-unused-vars
    const [sortModel, setSortModel] = useState(initialSortModel);
    const [fileInputValue, setFileInputValue] = useState("");
    const [tableHeight, setTableHeight] = useState(0);

    // every time the new data sets dialog (which is opened after selecting a file via the file picker in this dialog)
    // closes, clear the variable that tracks the most recently selected file
    useEffect(() => {
        if (!newDataSetDialogOpen) {
            setFileInputValue("");
        }
    }, [newDataSetDialogOpen]);

    /**
     * Submits the user-selected file from the file picker to the server to get some metadata from the file
     */
    const openConfirmUploadDialog = () => {
        // this handles when the user closes the file picker without selecting a file
        if (!fileInput.current || !fileInput.current.files.length) {
            return;
        }

        const fileData = new FormData();
        const file = fileInput.current.files[0];
        fileData.append("file", file);

        dispatch(showModal("Extracting metadata from the file you selected... Please standby for a moment."));

        // send the file to the server
        fetch(`${API_URL}/api/getCsvMetadata`, {
            method: "POST",
            body: fileData,
            credentials: "include",
        }).then((response) => {
            return response.json();
        }).then((data) => {
            const {
                status, message,
            } = data;
            if (status === 200) {
                // metadata from the file has now been returned
                // package it and send it to the next dialog where the user can edit the metadata
                setDataSetMetadata({
                    fileId: data.fileId,
                    fileName: file.name,
                    csvMetadata: JSON.parse(data.csvMetadata),
                });

                dispatch(setNewDataSetDialogOpen());
            } else {
                dispatch(setErrorMessage(message));
            }
        })
        // request was not able to be made
            .catch(() => {
                dispatch(setErrorMessage("There was an error on our end. Please reach out to us via email."));
            }).finally(() => {
                dispatch(hideModal());
            });
    };

    /**
     * Close this dialog
     */
    const close = () => {
        dispatch(setNewProjectDataSetsDialogClosed());
    };

    /**
     * Open the openNewProjectMetadataDialog dialog
     */
    const openNewProjectMetadataDialog = () => {
        dispatch(setNewProjectMetadataDialogOpen());
    };

    // define metadata about the column headers to be used in the datatable
    const columns = [
        {
            disableClickEventBubbling: true,
            field: "originalFileName",
            headerName: "Source File",
            width: 275,
            renderCell: (params) => {
                return renderTooltip(params.value);
            },
        }, {
            disableClickEventBubbling: true,
            field: "dataTableName",
            headerName: "DateTable Name",
            width: 275,
            renderCell: (params) => {
                return renderTooltip(params.value);
            },
        }, {
            cellClassName: datagridCssClasses.recordCount,
            disableClickEventBubbling: true,
            field: "recordCount",
            headerName: "Record Count",
            width: 275,
            type: "number",
            renderCell: (params) => {
                return renderTooltip(params.value);
            },
        }, {
            disableClickEventBubbling: true,
            field: "dateCreated",
            headerName: "Upload Date",
            width: 275,
            renderCell: (params) => {
                const date = dateString(params.value);
                return renderTooltip(date);
            },
        },
    ];

    /**
     * Render a tooltip for each cell of the dataGrid
     * @param {String} value - the string in the table cell
     * @return {JSX} a tooltip and the table cell
     */
    const renderTooltip = (value) => {
        return (
            <Tooltip title={value} arrow placement="left">
                <span className={classes.cellText}>
                    {value}
                </span>
            </Tooltip>
        );
    };

    // calculate the datatable width based on the width of all the column headers
    // 48 is the width of the column that holds the checkboxes - not included in the column headers
    const tableWidth = 48 + columns.reduce((sum, column) => {
        return sum = sum + column.width;
    }, 0);

    /**
     * Saves the selected checkboxes when any of the checkboxes are checked/unchecked
     * @param {Object} event - the event that is fired when any of the checkboxes are checked/unchecked
     */
    const updateSelectedDataSets = (event) => {
        setSelectedDataSets(rows.filter((dataSet) => {
            return event.rowIds.includes(dataSet.id);
        }));
    };

    /**
     * Opens the dialog that asks the user for confirmation that they want to delete the selected data set(s)
     */
    const openConfirmDeleteDataSetsDialog = () => {
        dispatch(setConfirmDeleteDataSetsDialogOpen());
    };

    /**
     * Determine if the Next button should be disabled
     * @return {Boolean} a boolean representing if the next button should be disabled
     */
    const isNextButtonDisabled = () => {
        return newDataSetDialogOpen ||
            (Boolean(selectedDataSets.length !== 1) && Boolean(selectedDataSets.length !== 2));
    };

    return (
        <>
            <ConfirmDeleteDataSetsDialog selectedDataSets={selectedDataSets} />
            <NewDataSetDialog
                fileId={dataSetMetadata.fileId}
                fileName={dataSetMetadata.fileName}
                csvMetadata={dataSetMetadata.csvMetadata}
                showInDialog={true}
                numRows={5}
            />
            <NewProjectMetadataDialog selectedDataSets={selectedDataSets} />
            <Dialog
                TransitionComponent={SlideLeftTransition}
                maxWidth={"lg"}
                fullWidth={true}
                open={dialogOpen}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}>
                <StyledDialogTitle className={classes.positionRelative}>
                    <IconButton onClick={close} className={classes.iconButton}>
                        <CloseIcon />
                    </IconButton>
                    <span>New Project</span>
                </StyledDialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <p className={classes.textAlignLeft}>Select Data Table(s)</p>
                    <div style={{
                        height: tableHeight,
                        width: tableWidth,
                    }}>
                        <DataGrid
                            ref={dataGrid}
                            className={datagridCssClasses.dataGrid}
                            rowHeight={26}
                            sortModel={sortModel}
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            autoHeight
                            checkboxSelection
                            disableClickEventBubbling
                            onSelectionChange={updateSelectedDataSets}
                        />
                    </div>
                </DialogContent>
                <DialogActions
                    className={classes.dialogActions}
                    style={{
                        width: dataGridLength,
                    }}
                >
                    <span>
                        <input
                            id={fileInputGuid}
                            type="file"
                            className={classes.fileInput}
                            ref={fileInput}
                            onChange={openConfirmUploadDialog}
                            value={fileInputValue}
                        />
                        <label htmlFor={fileInputGuid}>
                            <Button component="span" variant="outlined">
                                Upload File
                            </Button>
                        </label>
                    </span>
                    <Button
                        onClick={openConfirmDeleteDataSetsDialog}
                        variant="outlined"
                        disabled={newDataSetDialogOpen || !Boolean(selectedDataSets.length)}
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={openNewProjectMetadataDialog}
                        variant="outlined"
                        disabled={isNextButtonDisabled()}
                    >
                        Next
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
