import React from "react";
import {
    IconButton, TextField,
} from "@material-ui/core";
import {
    withStyles, makeStyles,
} from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import guidGenerator from "../../../sharedFunctions/guidGenerator";

const StyledIconButton = withStyles((theme) => {
    return {
        root: {
            top: "5px",
        },
    };
})(IconButton);

const StyledTextField = withStyles((theme) => {
    return {
        root: {
            width: "200px",
        },
    };
})(TextField);

/**
 * Styles to be used as classes in this component
 */
const useStyles = makeStyles({
    fileInput: {
        width: "0px",
        height: "0px",
    },
});

/**
 * The File Input Component
 */
/**
 * The FileInput Component
 * @param {Object} props data from the parent component
 * @return {JSX} the FileInput Component
 */
export default (props) => {
    const {
        key, label,
    } = props.metadata;

    const {
        columnIndex, columns, setColumns,
    } = props;

    const classes = useStyles();
    const fileInputGuid = guidGenerator();

    /**
     * Updates the data set's metadata when a file is chosen
     * @param {Object} event - the event that is fired when a file is chosen
     */
    const updateDataSetMetadata = (event) => {
        const file = event.nativeEvent.target.files[0];

        // if the user clicks cancel in the file picker
        if (!file) {
            return;
        }

        const fileReader = new FileReader();

        fileReader.onload = () => {
            setColumns([...columns].map((columnMetadata, index) => {
                if (index === columnIndex) {
                    return {
                        ...columnMetadata,
                        // create an array from the text read from the chosen file
                        // each array item will represent each line of text from the chosen file
                        [key]: fileReader.result.split("\n"),
                        fileName: file.name,
                    };
                } else {
                    return {
                        ...columnMetadata,
                    };
                }
            }));
        };

        // read text from the chosen file
        fileReader.readAsText(file);
    };

    return (
        <>
            &emsp;&emsp;&emsp;

            <StyledTextField
                label={label}
                disabled
                value={columns[columnIndex].fileName || ""}
            />

            <input
                id={fileInputGuid}
                type="file"
                className={classes.fileInput}
                onChange={(event) => {
                    updateDataSetMetadata(event);
                }}
            />
            <label htmlFor={fileInputGuid}>
                <StyledIconButton component="span" variant="outlined">
                    <AttachFileIcon />
                </StyledIconButton>
            </label>
        </>
    );
};
