/* /constants/action.types.js */

export const LOAD_MODEL = "LOAD_MODEL";
export const MODEL_REQUESTED = "MODEL_REQUESTED";
export const MODEL_LOADED = "MODEL_LOADED";

export const METADATA_LOADED = "METADATA_LOADED";
export const BASEMAP_LOADED = "BASEMAP_LOADED";
export const NODES_LOADED = "NODES_LOADED";
export const CURVES1_LOADED = "CURVES1_LOADED";
export const CURVES2_LOADED = "CURVES2_LOADED";
export const AXES_LOADED = "AXES_LOADED";

export const NODES_SELECTED = "NODES_SELECTED";
export const CLEAR_SELECTIONS = "CLEAR_SELECTIONS";

export const CLEAR_MODEL = "CLEAR_MODEL";

export const SET_BUILD_NEW_PROJECT_DIALOG_OPEN = "SET_BUILD_NEW_PROJECT_DIALOG_OPEN";
export const SET_BUILD_NEW_PROJECT_DIALOG_CLOSED = "SET_BUILD_NEW_PROJECT_DIALOG_CLOSED";

export const SET_CONFIRM_DELETE_DATA_SETS_DIALOG_OPEN = "SET_CONFIRM_DELETE_DATA_SETS_DIALOG_OPEN";
export const SET_CONFIRM_DELETE_DATA_SETS_DIALOG_CLOSED = "SET_CONFIRM_DELETE_DATA_SETS_DIALOG_CLOSED";

export const SET_CREATE_ACCOUNT_DIALOG_OPEN = "SET_CREATE_ACCOUNT_DIALOG_OPEN";
export const SET_CREATE_ACCOUNT_DIALOG_CLOSED = "SET_CREATE_ACCOUNT_DIALOG_CLOSED";

export const SET_LOAD_PROJECT_DIALOG_OPEN = "SET_LOAD_PROJECT_DIALOG_OPEN";
export const SET_LOAD_PROJECT_DIALOG_CLOSED = "SET_LOAD_PROJECT_DIALOG_CLOSED";

export const SET_LOGIN_DIALOG_OPEN = "SET_LOGIN_DIALOG_OPEN";
export const SET_LOGIN_DIALOG_CLOSED = "SET_LOGIN_DIALOG_CLOSED";

export const SET_NEW_PROJECT_DATA_SETS_DIALOG_OPEN = "SET_NEW_PROJECT_DATA_SETS_DIALOG_OPEN";
export const SET_NEW_PROJECT_DATA_SETS_DIALOG_CLOSED = "SET_NEW_PROJECT_DATA_SETS_DIALOG_CLOSED";

export const SET_NEW_PROJECT_METADATA_DIALOG_OPEN = "SET_NEW_PROJECT_METADATA_DIALOG_OPEN";
export const SET_NEW_PROJECT_METADATA_DIALOG_CLOSED = "SET_NEW_PROJECT_METADATA_DIALOG_CLOSED";

export const SET_NEW_DATA_SET_DIALOG_OPEN = "SET_NEW_DATA_SET_DIALOG_OPEN";
export const SET_NEW_DATA_SET_DIALOG_CLOSED = "SET_NEW_DATA_SET_DIALOG_CLOSED";

export const GET_USER_REQUEST = "GET_USER_REQUEST";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER = "LOGIN_USER";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER = "LOGOUT_USER";

export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SET_CONFIRMATION_MESSAGE = "SET_CONFIRMATION_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";

export const ADD_DATA_SET = "ADD_DATA_SET";
export const ADD_DATA_SET_REQUEST = "ADD_DATA_SET_REQUEST";
export const CLEAR_DATA_SETS = "CLEAR_DATA_SETS";
export const DELETE_DATA_SETS = "DELETE_DATA_SETS";
export const DELETE_DATA_SETS_REQUEST = "DELETE_DATA_SET_REQUESTS";
export const DELETE_TEMPORARY_DATA_SET = "DELETE_TEMPORARY_DATA_SET";
export const SET_DATA_SETS = "SET_DATA_SETS";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const ADD_BUILT_PROJECT = "ADD_BUILT_PROJECT";
export const BUILD_PROJECT_REQUEST = "BUILD_PROJECT_REQUEST";
export const CLEAR_ACTIVE_PROJECT = "CLEAR_ACTIVE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const SET_PROJECTS = "SET_PROJECTS";
