import {
    SHOW_MODAL, HIDE_MODAL,
} from "../constants/action-types";

const initialState = {
    open: false,
    message: "",
};

const modal = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                open: true,
                message: action.message,
            };

        case HIDE_MODAL:
            return initialState;

        default:
            return state;
    };
};

export default modal;
