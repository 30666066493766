import {
    makeStyles,
} from "@material-ui/core/styles";

export default makeStyles({
    recordCount: {
        display: "initial !important",
        alignItems: "initial !important",
    },
    dataGrid: {
        "margin": "0 auto",
        "color": "black",
        "border": "none",

        "& ::-webkit-scrollbar": {
            border: "solid 1px darkgray",
        },

        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgray",
        },

        "& .MuiDataGrid-columnsContainer": {
            borderBottom: "2px solid white",
        },

        "& .MuiDataGrid-colCell": {
            justifyContent: "center",
        },

        "& .MuiDataGrid-colCellWrapper ": {
            color: "white",
            backgroundColor: "darkgray",
        },

        "& .MuiDataGrid-colCell-draggable > span > button": {
            color: "white",
        },
        "& .MuiDataGrid-colCell:not(:first-child)": {
            borderLeft: "2px solid white",
        },

        "& .MuiDataGrid-cell": {
            borderTop: "2px solid white",
            borderBottom: "none",
        },

        "& .MuiDataGrid-cell:not(:first-child)": {
            borderLeft: "2px solid white",
        },

        "& .MuiDataGrid-columnSeparator": {
            display: "none",
        },

        "& .MuiDataGrid-row:nth-child(odd)": {
            backgroundColor: "gainsboro",
        },

        "& .MuiDataGrid-row:nth-child(even)": {
            backgroundColor: "whitesmoke",
        },

        "& .MuiDataGrid-colCellCheckbox .MuiCheckbox-root": {
            color: "white",
        },

        "& .MuiDataGrid-cellCheckbox .MuiCheckbox-root": {
            color: "black",
        },

        // "& .MuiDataGrid-cellCheckbox .MuiCheckbox-root": {
        //     color: "grey",
        //     opacity: "0.5",
        //     pointerEvents: "None",
        // },

        "& .MuiDataGrid-row:nth-child(odd).Mui-selected:hover": {
            backgroundColor: "gainsboro",
        },

        "& .MuiDataGrid-row:nth-child(even).Mui-selected:hover": {
            backgroundColor: "whitesmoke",
        },
    },
});
