import {
    BASEMAP_LOADED, CLEAR_MODEL, CLEAR_SELECTIONS, MODEL_REQUESTED, NODES_SELECTED,
} from "../constants/action-types";

export const loadModel = (payload) => {
    return {
        type: MODEL_REQUESTED,
        payload,
    };
};

export const basemapLoaded = (payload) => {
    return {
        type: BASEMAP_LOADED,
        payload,
    };
};

export const nodesSelected = (payload) => {
    return {
        type: NODES_SELECTED,
        payload,
    };
};

export const clearSelections = (payload) => {
    return {
        type: CLEAR_SELECTIONS,
        payload,
    };
};

export const clearModel = (payload) => {
    return {
        type: CLEAR_MODEL,
    };
};
