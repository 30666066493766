import React, {
    useEffect, useState,
} from "react";
import {
    API_URL,
} from "../../config.js";
import {
    useDispatch, useSelector,
} from "react-redux";
import {
    setCreateAccountDialogClosed, setLoginDialogOpen,
} from "../actions/dialog";
import {
    Button, Dialog, IconButton, Slide,
} from "@material-ui/core";
import {
    StyledDialogTitle, StyledDialogContent, StyledDialogContentText, StyledDialogActions,
}
    from "../styles/dialog";
import {
    makeStyles,
} from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import input from "../sharedComponents/DialogTextField";
import {
    setConfirmationMessage,
} from "../actions/alert";
import {
    setErrorMessage,
} from "../actions/alert";

const fieldBlankText = "You can't leave this field blank.";
const passwordsDifferent = "Passwords do not match.";

const SlideLeftTransition = React.forwardRef((props, ref) => {
    return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    iconButton: {
        position: "absolute",
        left: "0%",
        top: "50%",
        transform: "translateY(-50%)",
    },
    positionRelative: {
        position: "relative",
    },
});

/**
 * The CreateAccount Component
 * @return {JSX} the CreateAccount Component
 */
export default () => {
    const classes = useStyles();

    const dialogOpen = useSelector((state) => {
        return state.dialogs.createAccountDialogOpen;
    });
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameHelperText, setFirstNameHelperText] = useState("");

    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState(false);
    const [lastNameHelperText, setLastNameHelperText] = useState("");

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailHelperText, setEmailHelperText] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordHelperText, setPasswordHelperText] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState("");

    const [message, setMessage] = useState("");

    /**
     * Reset the message at the bottom of the form, every time any of the text fields change
     */
    useEffect(() => {
        if (message.length) {
            setMessage("");
        }
    }, [firstName, lastName, email, password, confirmPassword]);

    /**
     * Reset the firstName error variables every time the firstName field changes
     */
    useEffect(() => {
        if (firstNameError) {
            setFirstNameError(false);
            setFirstNameHelperText("");
        }
    }, [firstName]);

    /**
     * Reset the lastName error variables every time the lastName field changes
     */
    useEffect(() => {
        if (lastNameError) {
            setLastNameError(false);
            setLastNameHelperText("");
        }
    }, [lastName]);

    /**
     * Reset the email error variables every time the email field changes
     */
    useEffect(() => {
        if (emailError) {
            setEmailError(false);
            setEmailHelperText("");
        }
    }, [email]);

    /**
     * Reset the password error variables every time the password field changes
     * Reset the confirmPassword error variables if the password field changes,
     * and the user submitted the form and the passwords didn't match
     */
    useEffect(() => {
        if (passwordError) {
            setPasswordError(false);
            setPasswordHelperText("");
        }

        if (confirmPasswordError && confirmPasswordHelperText === passwordsDifferent) {
            setConfirmPasswordError(false);
            setConfirmPasswordHelperText("");
        }
    }, [password]);

    /**
     * Reset the confirmPassword error variables every time the confirmPassword field changes
     */
    useEffect(() => {
        if (confirmPasswordError) {
            setConfirmPasswordError(false);
            setConfirmPasswordHelperText("");
        }
    }, [confirmPassword]);

    /**
     * Attempt to create an account via the provided information
     */
    const createAccount = () => {
        resetErrors();

        if (!firstName.length) {
            setFirstNameError(true);
            setFirstNameHelperText(fieldBlankText);
        }
        if (!lastName.length) {
            setLastNameError(true);
            setLastNameHelperText(fieldBlankText);
        }
        if (!email.length) {
            setEmailError(true);
            setEmailHelperText(fieldBlankText);
        }
        if (!password.length) {
            setPasswordError(true);
            setPasswordHelperText(fieldBlankText);
        }
        if (!confirmPassword.length) {
            setConfirmPasswordError(true);
            setConfirmPasswordHelperText(fieldBlankText);
        }
        if (!firstName.length || !lastName.length || !email.length ||
            !password.length || !confirmPassword.length) {
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError(true);
            setConfirmPasswordHelperText(passwordsDifferent);
            return;
        }

        fetch(`${API_URL}/api/createAccount`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                confirmPassword: confirmPassword,
            }),
            credentials: "include",
        }).then((response) => {
            return response.json();
        // request returned successfully
        }).then((data) => {
            const {
                status, message,
            } = data;
            if (status === 200) {
                goToLoginDialog();
                dispatch(setConfirmationMessage(message));
            } else if (status === 409) {
                setEmailError(true);
                setEmailHelperText(message);
            } else {
                setMessage(message);
            }
        // request was not able to be made
        }).catch(() => {
            dispatch(setErrorMessage("There was an error on our end. Please reach out to us via email."));
        });
    };

    /**
     * Detects changes to the first name field and updates the state variable
     * @param {Object} event the event that is triggered every time the first name field is typed in to
     */
    const updateFirstName = (event) => {
        setFirstName(event.target.value);
    };

    /**
     * Detects changes to the last name field and updates the state variable
     * @param {Object} event the event that is triggered every time the last name field is typed in to
     */
    const updateLastName = (event) => {
        setLastName(event.target.value);
    };

    /**
     * Detects changes to the email field and updates the state variable
     * @param {Object} event the event that is triggered every time the email field is typed in to
     */
    const updateEmail = (event) => {
        setEmail(event.target.value);
    };

    /**
     * Detects changes to the password field and updates the state variable
     * @param {Object} event the event that is triggered every time the password field is typed in to
     */
    const updatePassword = (event) => {
        setPassword(event.target.value);
    };

    /**
     * Detects changes to the confirm password field and updates the state variable
     * @param {Object} event the event that is triggered every time the confirm password field is typed in to
     */
    const updateConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    };

    /**
     * Reset all the error variables
     */
    const resetErrors = () => {
        setFirstNameError(false);
        setFirstNameHelperText("");

        setLastNameError(false);
        setLastNameHelperText("");

        setEmailError(false);
        setEmailHelperText("");

        setPasswordError(false);
        setPasswordHelperText("");

        setConfirmPasswordError(false);
        setConfirmPasswordHelperText("");
    };

    /**
     * Reset all text fields and all error variables
     */
    const resetFields = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setMessage("");
        resetErrors();
    };

    /**
     * Go back to the login dialog
     */
    const goToLoginDialog = () => {
        dispatch(setCreateAccountDialogClosed());
        dispatch(setLoginDialogOpen());
        resetFields();
    };

    return (
        <Dialog
            TransitionComponent={SlideLeftTransition}
            maxWidth={"xs"}
            fullWidth={true}
            open={dialogOpen}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <StyledDialogTitle className={classes.positionRelative}>
                <IconButton onClick={goToLoginDialog} className={classes.iconButton}>
                    <ArrowBackIcon />
                </IconButton>
                <span>Request Account</span>
            </StyledDialogTitle>
            <StyledDialogContent>
                {input("First Name", updateFirstName, "text", firstName, firstNameError, firstNameHelperText)}
                {input("Last Name", updateLastName, "text", lastName, lastNameError, lastNameHelperText)}
                {input("Email", updateEmail, "email", email, emailError, emailHelperText)}
                {input("Password", updatePassword, "password", password, passwordError, passwordHelperText)}
                {input("Confirm Password", updateConfirmPassword, "password", confirmPassword, confirmPasswordError,
                    confirmPasswordHelperText)}
            </StyledDialogContent>
            <StyledDialogContentText>
                {message}
            </StyledDialogContentText>
            <StyledDialogActions>
                <Button onClick={createAccount} variant="outlined">
                    Submit
                </Button>
            </StyledDialogActions>
        </Dialog>
    );
};
