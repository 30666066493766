/* eslint-disable require-jsdoc */
import {
    all, call, fork, put, takeEvery,
} from "redux-saga/effects";
import axios from "axios";

const getData = (options) => {
    return axios(options);
};

function* getModelWorker(action) {
    const metadataUrl = `${action.payload.model_url}/meta`;
    const configUrl = `${action.payload.model_url}/config`;
    // const basemap_url = `${action.payload.model_url}/basemap`;
    const nodesUrl = `${action.payload.model_url}/nodes`;
    const curves1Url = `${action.payload.model_url}/curves1`;
    const curves2Url = `${action.payload.model_url}/curves2`;
    const axesUrl = `${action.payload.model_url}/axes`;

    try {
        // run model component api retrieval tasks in parallel
        // const [metadataPayload, basemapPayload, nodesPayload, curves1Payload, curves2Payload, axesPayload] =
        // yield all([
        const [metadataPayload, configPayload, nodesPayload, curves1Payload, curves2Payload, axesPayload] =
        yield all([
            call(getData, {
                method: "get",
                url: metadataUrl,
            }),
            call(getData, {
                method: "get",
                url: configUrl,
            }),
            // call(getData, { method: "get", url: basemap_url }),
            call(getData, {
                method: "get",
                url: nodesUrl,
            }),
            call(getData, {
                method: "get",
                url: curves1Url,
            }),
            call(getData, {
                method: "get",
                url: curves2Url,
            }),
            call(getData, {
                method: "get",
                url: axesUrl,
            }),
        ]);

        // send the results to the corresponding reducers
        yield all([
            put({
                type: "METADATA_LOADED",
                metadataPayload,
            }),
            put({
                type: "CONFIG_LOADED",
                configPayload,
            }),
            // put({ type: "BASEMAP_LOADED", basemapPayload }),
            put({
                type: "NODES_LOADED",
                nodesPayload,
            }),
            put({
                type: "CURVES1_LOADED",
                curves1Payload,
            }),
            put({
                type: "CURVES2_LOADED",
                curves2Payload,
            }),
            put({
                type: "AXES_LOADED",
                axesPayload,
            }),
            put({
                type: "SET_PROJECT_ID",
                projectId: action.payload.model_url.split("/").pop(),
            }),
        ]);

        // signal done
        yield put({
            type: "MODEL_LOADED",
        });
    } catch (e) {
        yield put({
            type: "API_ERRORED",
            payload: e,
        });
    }
};

function* getModelWatcher() {
    yield takeEvery("MODEL_REQUESTED", getModelWorker);
};

const modelSagas = [
    fork(getModelWatcher),
];

export default modelSagas;
