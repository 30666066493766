import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Group, Rect, Text,
} from "react-konva";

const PADDING = 10;

/**
 * The Label Component
 * @param {Object} props data from the parent component
 * @return {JSX} the Label Component
 */
export default (props) => {
    const {
        labelText, x, y, fontSize, fontStyle, fontColor, textPosition, canvasConfig,
    } = props;

    const ref = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [labelHeight, setLabelHeight] = useState(0);

    useEffect(() => {
        if (ref.current) {
            setLabelWidth(ref.current.getTextWidth());
            setLabelHeight(ref.current.height());
        }
    }, [ref]);

    const getLabelX = () => {
        let labelX;
        switch (textPosition) {
            case "left":
                labelX = x + PADDING;
                break;
            case "center":
                labelX = x - labelWidth / 2;
                break;
            case "right":
                labelX = x - labelWidth - PADDING;
                break;
            default:
                labelX = x;
        }
        return labelX;
    };

    return (
        <Group
            x={getLabelX()}
            y={y - labelHeight / 2}
        >
            <Rect
                x={0}
                y={0}
                width={labelWidth}
                height={labelHeight}
                fill={canvasConfig.labelRectColor}
                stroke={canvasConfig.labelRectColor}
                strokeWidth={canvasConfig.labelRectBuffer}
                opacity={canvasConfig.labelRectOpacity}
            />

            <Text
                ref={ref}
                text={labelText}
                x={0}
                y={0}
                fontFamily={canvasConfig.labelFontFamily}
                fontSize={fontSize}
                fontStyle={fontStyle}
                fill={fontColor}
                wrap="none"
            />
        </Group>
    );
};
