/**
 * Generate a unique GUID
 * https://stackoverflow.com/questions/6860853/generate-random-string-for-div-id
 * @return {String} a global unique identifier
 */
export default () => {
    const s4 = () => {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };

    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};
